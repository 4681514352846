const config = {
	supportedCountries: [
		{
			iso: 'US',
			currency: 'USD',
			language: 'en',
			name: 'United States',
			defaultLocale: 'US',
		},
		{
			iso: 'AT',
			currency: 'EUR',
			language: 'en',
			name: 'Austria',
			defaultLocale: 'CH',
		},
		{
			iso: 'AL',
			currency: 'EUR',
			language: 'en',
			name: 'Albania',
			defaultLocale: 'CH',
		},
		{
			iso: 'AR',
			currency: 'USD',
			language: 'en',
			name: 'Argentina',
			defaultLocale: 'US',
		},
		{
			iso: 'AU',
			currency: 'USD',
			language: 'en',
			name: 'Australia',
			defaultLocale: 'US',
		},
		{
			iso: 'BH',
			currency: 'AED',
			language: 'en',
			name: 'Bahrain',
			defaultLocale: 'SA',
		},
		{
			iso: 'BE',
			currency: 'EUR',
			language: 'en',
			name: 'Belgium',
			defaultLocale: 'CH',
		},
		{
			iso: 'BO',
			currency: 'USD',
			language: 'en',
			name: 'Bolivia',
			defaultLocale: 'US',
		},
		{
			iso: 'BG',
			currency: 'EUR',
			language: 'en',
			name: 'Bulgaria',
			defaultLocale: 'CH',
		},
		{
			iso: 'CA',
			currency: 'USD',
			language: 'en',
			name: 'Canada',
			defaultLocale: 'US',
		},
		{
			iso: 'CL',
			currency: 'USD',
			language: 'en',
			name: 'Chile',
			defaultLocale: 'US',
		},
		{
			iso: 'CN',
			currency: 'USD',
			language: 'en',
			name: 'China',
			defaultLocale: 'HK',
		},
		{
			iso: 'CO',
			currency: 'USD',
			language: 'en',
			name: 'Colombia',
			defaultLocale: 'US',
		},
		{
			iso: 'HR',
			currency: 'EUR',
			language: 'en',
			name: 'Croatia',
			defaultLocale: 'CH',
		},
		{
			iso: 'CZ',
			currency: 'EUR',
			language: 'en',
			name: 'Czech Republic',
			defaultLocale: 'CH',
		},
		{
			iso: 'DK',
			currency: 'EUR',
			language: 'en',
			name: 'Denmark',
			defaultLocale: 'CH',
		},
		{
			iso: 'EG',
			currency: 'USD',
			language: 'en',
			name: 'Egypt',
			defaultLocale: 'SA',
		},
		{
			iso: 'FI',
			currency: 'EUR',
			language: 'en',
			name: 'Finland',
			defaultLocale: 'CH',
		},
		{
			iso: 'FR',
			currency: 'EUR',
			language: 'en',
			name: 'France',
			defaultLocale: 'CH',
		},
		{
			iso: 'DE',
			currency: 'EUR',
			language: 'en',
			name: 'Germany',
			defaultLocale: 'CH',
		},
		{
			iso: 'GR',
			currency: 'EUR',
			language: 'en',
			name: 'Greece',
			defaultLocale: 'CH',
		},
		{
			iso: 'HK',
			currency: 'HKD',
			language: 'en',
			name: 'Hong Kong, SAR of China',
			defaultLocale: 'HK',
		},
		{
			iso: 'HU',
			currency: 'EUR',
			language: 'en',
			name: 'Hungary',
			defaultLocale: 'CH',
		},
		{
			iso: 'IS',
			currency: 'EUR',
			language: 'en',
			name: 'Iceland',
			defaultLocale: 'CH',
		},
		{
			iso: 'IN',
			currency: 'USD',
			language: 'en',
			name: 'India',
			defaultLocale: 'HK',
		},
		{
			iso: 'ID',
			currency: 'USD',
			language: 'en',
			name: 'Indonesia',
			defaultLocale: 'HK',
		},
		{
			iso: 'IE',
			currency: 'EUR',
			language: 'en',
			name: 'Ireland',
			defaultLocale: 'CH',
		},
		{
			iso: 'IL',
			currency: 'EUR',
			language: 'en',
			name: 'Israel',
			defaultLocale: 'CH',
		},
		{
			iso: 'IT',
			currency: 'EUR',
			language: 'en',
			name: 'Italy',
			defaultLocale: 'CH',
		},
		{
			iso: 'JP',
			currency: 'USD',
			language: 'en',
			name: 'Japan',
			defaultLocale: 'HK',
		},
		{
			iso: 'JO',
			currency: 'AED',
			language: 'en',
			name: 'Jordan',
			defaultLocale: 'SA',
		},
		{
			iso: 'KW',
			currency: 'AED',
			language: 'en',
			name: 'Kuwait',
			defaultLocale: 'SA',
		},
		{
			iso: 'LB',
			currency: 'AED',
			language: 'en',
			name: 'Lebanon',
			defaultLocale: 'SA',
		},
		{
			iso: 'LI',
			currency: 'EUR',
			language: 'en',
			name: 'Liechtenstein',
			defaultLocale: 'CH',
		},
		{
			iso: 'LU',
			currency: 'EUR',
			language: 'en',
			name: 'Luxembourg',
			defaultLocale: 'CH',
		},
		{
			iso: 'MO',
			currency: 'EUR',
			language: 'en',
			name: 'Macau, SAR of China',
			defaultLocale: 'HK',
		},
		{
			iso: 'MY',
			currency: 'SGD',
			language: 'en',
			name: 'Malaysia',
			defaultLocale: 'HK',
		},
		{
			iso: 'MX',
			currency: 'USD',
			language: 'en',
			name: 'Mexico',
			defaultLocale: 'US',
		},
		{
			iso: 'MC',
			currency: 'EUR',
			language: 'en',
			name: 'Monaco',
			defaultLocale: 'CH',
		},
		{
			iso: 'NL',
			currency: 'EUR',
			language: 'en',
			name: 'Netherlands',
			defaultLocale: 'CH',
		},
		{
			iso: 'NZ',
			currency: 'USD',
			language: 'en',
			name: 'New Zealand',
			defaultLocale: 'HK',
		},
		{
			iso: 'NO',
			currency: 'EUR',
			language: 'en',
			name: 'Norway',
			defaultLocale: 'CH',
		},
		{
			iso: 'OM',
			currency: 'AED',
			language: 'en',
			name: 'Oman',
			defaultLocale: 'SA',
		},
		{
			iso: 'PE',
			currency: 'USD',
			language: 'en',
			name: 'Peru',
			defaultLocale: 'US',
		},
		{
			iso: 'PH',
			currency: 'USD',
			language: 'en',
			name: 'Philippines',
			defaultLocale: 'HK',
		},
		{
			iso: 'PL',
			currency: 'EUR',
			language: 'en',
			name: 'Poland',
			defaultLocale: 'CH',
		},
		{
			iso: 'PT',
			currency: 'EUR',
			language: 'en',
			name: 'Portugal',
			defaultLocale: 'CH',
		},
		{
			iso: 'QA',
			currency: 'AED',
			language: 'en',
			name: 'Qatar',
			defaultLocale: 'SA',
		},
		{
			iso: 'RO',
			currency: 'EUR',
			language: 'en',
			name: 'Romania',
			defaultLocale: 'CH',
		},
		{
			iso: 'SM',
			currency: 'EUR',
			language: 'en',
			name: 'San Marino',
			defaultLocale: 'CH',
		},
		{
			iso: 'SA',
			currency: 'AED',
			language: 'en',
			name: 'Saudi Arabia',
			defaultLocale: 'SA',
		},
		{
			iso: 'RS',
			currency: 'EUR',
			language: 'en',
			name: 'Serbia',
			defaultLocale: 'CH',
		},
		{
			iso: 'SG',
			currency: 'SGD',
			language: 'en',
			name: 'Singapore',
			defaultLocale: 'SG',
		},
		{
			iso: 'ZA',
			currency: 'USD',
			language: 'en',
			name: 'South Africa',
			defaultLocale: 'SA',
		},
		{
			iso: 'SK',
			currency: 'USD',
			language: 'en',
			name: 'South Korea',
			defaultLocale: 'HK',
		},
		{
			iso: 'ES',
			currency: 'EUR',
			language: 'en',
			name: 'Spain',
			defaultLocale: 'CH',
		},
		{
			iso: 'SE',
			currency: 'EUR',
			language: 'en',
			name: 'Sweden',
			defaultLocale: 'CH',
		},
		{
			iso: 'CH',
			currency: 'CHF',
			language: 'en',
			name: 'Switzerland',
			defaultLocale: 'CH',
		},
		{
			iso: 'TW',
			currency: 'HKD',
			language: 'en',
			name: 'Taiwan, China',
			defaultLocale: 'HK',
		},
		{
			iso: 'TH',
			currency: 'USD',
			language: 'en',
			name: 'Thailand',
			defaultLocale: 'HK',
		},
		{
			iso: 'TR',
			currency: 'EUR',
			language: 'en',
			name: 'Turkey',
			defaultLocale: 'CH',
		},
		{
			iso: 'AE',
			currency: 'AED',
			language: 'en',
			name: 'United Arab Emirates',
			defaultLocale: 'SA',
		},
		{
			iso: 'GB',
			currency: 'GBP',
			language: 'en',
			name: 'United Kingdom',
			defaultLocale: 'CH',
		},
		{
			iso: 'UY',
			currency: 'USD',
			language: 'en',
			name: 'Uruguay',
			defaultLocale: 'US',
		},
		{
			iso: 'VA',
			currency: 'EUR',
			language: 'en',
			name: 'Vatican City',
			defaultLocale: 'CH',
		},
		{
			iso: 'VN',
			currency: 'USD',
			language: 'en',
			name: 'Vietnam',
			defaultLocale: 'HK',
		},
	],
}

export const supportedCountries = config.supportedCountries as {
	iso: string
	currency: string
	language: string
	name: string
	defaultLocale?: string
}[]

export default config
