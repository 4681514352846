/* PWA Hooks */
import { getAppOrigin } from '@salesforce/pwa-kit-react-sdk/utils/url'

/* Helpers */
import { getExpectedImgixURL } from '../imgixHelper'
import { fetchSafely } from '../fetchHelper'

/* Shared Types */
import { JSONObject } from '@lib/types/JSONObject.models'
import {
	editorialType,
	editorialsType,
	rolexImagesType,
	imagesType,
	imagesListType,
} from '@lib/types/Watch.models'
import {
	PLPDataSEOCarousel,
	PLPDataSEOImages,
} from '@components/watches/PLPType.models'
import { ScapiProduct } from '@lib/types/scapi/product.models'
import { SearchResponse } from '@helpers/redirectHelper/redirectHelper.models'

/* Fixed Config */
const postedImageHost: string = 'https://govberg-sfcc.imgix.net'
const postedBespokeImageHost: string = 'https://watchbox-cdn.imgix.net'
const apiBaseFolder: string = '/api/'

/* Fixed Config for Suggestions */
const skipCollectionPrefixes: string[] = ['collection-', '-collection']
const skipParentNames: string[] = ['the 1916 company', 'watches', 'pre-owned']

/* Config */
import { accordionExclusions, navExclusions } from '../../config/exclusions'

/* Shared Types */
export interface HookCategoryRecordType {
	c_editorialTiles?: editorialType[]
	fixedEditorials?: editorialsType
	randomEditorials?: editorialType[]
	[key: string]: editorialType[] | editorialsType | undefined
}

/* Local Types */
import {
	UniqueTerms,
	RedirectFound,
	FlattenReturn,
	SortingOptionsType,
	ProductsType,
	HitsType,
	AccordionDataType,
	AccordionContentType,
	AccordionType,
	AccordionReturnType,
	SuggestionsType,
	AllBrandsDataType,
} from './ocapiCustomAPIHelper.models'

/* Data Helpers */
const addUniqueTerm = (
	uniqueTerms: UniqueTerms,
	term: string,
	parentTerm: string
): UniqueTerms => {
	if (term && term.trim().length > 2) {
		const isBadParentName: boolean =
			parentTerm &&
			skipParentNames.some(
				(name) => parentTerm.toLowerCase().indexOf(name) > -1
			)
				? true
				: false
		const val: string = (
			(!isBadParentName && parentTerm && term.indexOf(parentTerm) == -1
				? parentTerm + ' '
				: '') + term
		).trim()
		const key: string = val.toLowerCase().trim()
		if (key in uniqueTerms === false) {
			uniqueTerms[key] = [val, val]
		}
	}
	return uniqueTerms
}

const flattenStrapiImages = (
	data: JSONObject | editorialType,
	retainOthers?: boolean
): FlattenReturn => {
	const ret: FlattenReturn = {}
	Object.entries(data).forEach(([key, value]) => {
		if (value && typeof value === 'object') {
			if (value.url && value.mime) {
				ret[key] = getExpectedImgixURL(value.url)
				if (value.width && value.height) {
					ret[key + '_size'] = {
						width: value.width,
						height: value.height,
						ratio: value.width / value.height,
					}
				}
			} else if (key.indexOf('img') > -1) {
				ret[key] = ''
			} else if (retainOthers) {
				ret[key] = value
			}
		} else if (retainOthers) {
			ret[key] = value
		}
	})
	return ret
}

/* Data Hooks */
export const hookCategoryRecord = (
	data: HookCategoryRecordType,
	hostEnvironment: string
): HookCategoryRecordType => {
	/* Incoming data key */
	const tileKey: string = 'c_editorialTiles'

	if (data?.[tileKey] && (data[tileKey] as editorialType[]).length > 0) {
		/* Get Actual Environment (sbx, dev, stg, prd) */
		const getActualEnvironment = (environment: string): string => {
			const envID: string =
				environment && environment.length > 3 ? environment.slice(-3) : ''
			return !isNaN(parseInt(envID)) ? 'sbx' : envID
		}

		/* Validate Environment and End Date */
		const validateEnvironmentAndEndDate = (
			editorial: editorialType
		): boolean => {
			const actualEnv: string = getActualEnvironment(hostEnvironment)

			let { environment, enddate } = editorial
			environment = environment || ''
			enddate = enddate || ''
			let ret: boolean = true
			if (environment.length > 3) {
				ret =
					actualEnv && environment.indexOf('|' + actualEnv + '|') > -1
						? true
						: false
			}
			if (ret && enddate) {
				ret = new Date().getTime() < new Date(enddate).getTime()
			}

			return ret
		}

		/* Filter by Environment and Flatten Images */
		const editorials = (data[tileKey] as editorialType[])
			.filter((editorial) => {
				return validateEnvironmentAndEndDate(editorial)
			})
			.map((editorial) => {
				delete editorial.environment
				return flattenStrapiImages(editorial, true)
			})

		/* Fixed position editorials? */
		const fixedEditorials: editorialsType = {}
		editorials
			?.filter((editorial) => editorial.position)
			.forEach((editorial: editorialType) => {
				const { position } = editorial
				if (position) {
					if (!fixedEditorials[position]) {
						fixedEditorials[position] = []
					}
					fixedEditorials[position].push(editorial)
				}
			})

		/* Choose a random editorial from each position */
		Object.keys(fixedEditorials).forEach((key) => {
			fixedEditorials[key] = fixedEditorials[key]
				.sort(() => Math.random() - 0.5)
				.slice(0, 1)
		})

		/* Randomize any-position editorials */
		const randomEditorials: editorialType[] =
			editorials
				?.filter((editorial: editorialType) => !editorial.position)
				.sort(() => Math.random() - 0.5)
				.map((editorial) => {
					return editorial
				}) || []

		/* Send converted data to client */
		return {
			...data,
			...{
				fixedEditorials: fixedEditorials,
				randomEditorials: randomEditorials,
				[tileKey]: undefined,
			},
		}
	}

	/* Return original data */
	return data
}
export const hookCustomObjectRecord = (data: JSONObject): JSONObject => {
	/* Get hero images */
	const flattenImages = flattenStrapiImages(data)
	if (Object.keys(flattenImages).length > 0) {
		data = Object.assign(data, flattenImages)
	}

	if (data.images) {
		/* Get carousel images */
		const carousel: PLPDataSEOCarousel = {}
		;(data.images as unknown as PLPDataSEOImages[]).forEach(
			(val: PLPDataSEOImages) => {
				const { cgid, image } = val
				if (cgid && image) {
					const { url } = image
					if (url) {
						carousel[cgid] = getExpectedImgixURL(url)
					}
				}
			}
		)
		delete data.images
		data.carousel = carousel
	}

	return data
}

export const hookProductSearchRecords = (
	products: ProductsType,
	offset?: number,
	layout?: string
) => {
	const ret: HitsType = {}
	const hitsKey = 'hits'
	if (products[hitsKey]) {
		const hits = products[hitsKey] as unknown as HitsType[]
		const itemsRemove: string[] = [
			'c_images',
			'c_imageList',
			'hit_type',
			'link',
			'price_per_unit',
			'product_type',
			'represented_product',
			'represented_products',
			'variation_attributes',
		]
		offset = offset || 0

		hits.forEach((item, index: number) => {
			const images: imagesType[] = []
			const imagesUnique: string[] = []
			if (images.length == 0 && item.c_rolexImages) {
				const mapAlts: JSONObject = {
					'CPO Black': 'Vertical',
					'360-1': 'Vertical 2',
					'360-5': 'Vertical 3',
				}

				try {
					const rolexImagesJSON = JSON.parse(item.c_rolexImages.toString())
					if (rolexImagesJSON && rolexImagesJSON.length) {
						Object.entries(rolexImagesJSON).forEach(([, value]) => {
							const { Image_Type, Image_Small } = value as rolexImagesType
							if (Image_Small && Image_Type) {
								if (Image_Type in mapAlts) {
									const fullURL: string =
										(Image_Small.indexOf('://') == -1
											? postedBespokeImageHost + '/'
											: '') +
										Image_Small.replace(/^\//, '').replace(
											'.netposted',
											'.net/posted'
										)
									if (imagesUnique.indexOf(fullURL) == -1) {
										imagesUnique.push(fullURL)
										images.push({
											link: fullURL,
											alt: (mapAlts?.[Image_Type] ?? '').toString(),
										})
									}
								}
							}
						})
					}
				} catch (e) {}
			}

			if (images.length == 0 && item.c_imageList) {
				try {
					const imageListJSON = JSON.parse(item.c_imageList.toString())
					if (imageListJSON && imageListJSON.length) {
						Object.entries(imageListJSON).forEach(([, value]) => {
							const { url, alt } = value as imagesListType
							if (url) {
								const fullURL: string =
									(url.indexOf('://') == -1 ? postedImageHost + '/' : '') +
									url.replace(/^\//, '').replace('.netposted', '.net/posted')
								if (imagesUnique.indexOf(fullURL) == -1) {
									imagesUnique.push(fullURL)
									images.push({
										link: fullURL,
										alt: alt || '',
									})
								}
							}
						})
					}
				} catch (e) {}
			}

			/* Bespoke Hack for Launch */
			if (images.length == 0 && item.productId) {
				const rlxPrefix: string = 'rlx-'
				if (item.productId.toString().indexOf(rlxPrefix) == 0) {
					const useSku: string = item.c_sku
						? item.c_sku.toString().toLowerCase().replace(rlxPrefix, '')
						: item.productId.toString().replace(rlxPrefix, '')
					const fullURL: string = `https://govberg-sfcc.imgix.net/rlx/watch_assets_upright/landscape_assets/${useSku}_collection_upright_landscape.png?auto=format,compress`
					images.push({
						link: fullURL,
						alt: 'Vertical',
					})
				}
			}

			if (images.length > 0) {
				item.images = images
			} else {
				if (item.c_images) {
					Object.entries(item.c_images).forEach(([, [img, alt]]) => {
						if (img) {
							images.push({
								link: img,
								alt: alt || '',
							})
						}
					})
				}
				if (images.length > 0) {
					item.images = images
				}
			}

			itemsRemove.forEach((key) => {
				const camelKey: string = key.replace(/_([a-z])/g, (g) =>
					g[1].toUpperCase()
				)
				if (key in item) {
					delete item[key]
				}
				if (key !== camelKey && camelKey in item) {
					delete item[camelKey]
				}
			})

			ret[(offset || 0) + index] = item
		})
	}

	/* Filter Sorting Options? */
	const canIncludeOption = (
		layout: string | undefined,
		key: string
	): boolean => {
		const mainDefault: string = 'most-popular'
		const watchesDefault: string = 'best-matches-watches'
		const preOwnedDefault: string = 'best-matches-pre-owned'
		const jewelryDefault: string = 'best-matches-jewelry'
		if (layout) {
			if (layout === 'watches' || layout === 'patek-philippe') {
				return (
					[mainDefault, preOwnedDefault, jewelryDefault].includes(key) === false
				)
			} else if (layout === 'pre-owned') {
				return (
					[mainDefault, watchesDefault, jewelryDefault].includes(key) === false
				)
			} else if (layout === 'jewelry') {
				return (
					[mainDefault, preOwnedDefault, watchesDefault].includes(key) === false
				)
			}
		}
		return (
			[watchesDefault, preOwnedDefault, jewelryDefault].includes(key) === false
		)
	}
	const hookSortingOptions = (
		layout: string,
		sortingOptions: SortingOptionsType[]
	): SortingOptionsType[] => {
		return sortingOptions
			? sortingOptions.filter((option) =>
					canIncludeOption(layout, option.id as string)
			  )
			: []
	}

	return {
		hits: ret,
		total: products.total,
		refinements: products.refinements,
		selectedRefinements: products.selectedRefinements,
		sortingOptions: hookSortingOptions(layout || '', products.sortingOptions),
		searchDrivenRedirect:
			products.searchPhraseSuggestions?.c_searchRedirect?.toString().trim() ||
			'',
	}
}

export const hookAccordionData = (
	data: AccordionDataType,
	country?: string
): AccordionReturnType => {
	const ret = {} as AccordionReturnType

	/* TODO: move this to /api/object/ when id=accordions to parse once on server */
	const c_content: AccordionContentType =
		data && 'c_content' in data ? JSON.parse(data.c_content || '') : {}
	const accordions: AccordionType[] =
		c_content && 'data' in c_content ? c_content.data : []

	if (accordions) {
		/* Any exclusions? */
		const exclusions: string[] =
			country && accordionExclusions ? accordionExclusions[country] || [] : []
		const reg: RegExp = new RegExp(exclusions.join('|'))

		accordions.forEach((accordion) => {
			if (accordion && 'slug' in accordion && accordion.enabled) {
				/* Remove exclusions? */
				if (exclusions.length > 0) {
					accordion.entries = accordion.entries.filter((entry) => {
						if (entry.url && reg.test(entry.url)) {
							return false
						}
						return true
					})
				}
				ret[accordion.slug] = {
					entries: accordion.entries,
				}
			}
		})
	}
	return ret
}

export const hookAllBrandsRecord = (
	country: string,
	data: AllBrandsDataType[]
): AllBrandsDataType[] => {
	if (data && navExclusions && country && country in navExclusions) {
		const reg: RegExp = new RegExp(navExclusions[country].join('|'))
		return data.filter((category) => {
			return !category.id || !reg.test(category.id)
		})
	}
	return data
}

export const hookSuggestionsRecord = (
	data: SuggestionsType[],
	onlyRedirect?: boolean
): UniqueTerms | RedirectFound => {
	let uniqueTerms: UniqueTerms = {}
	let redirectFound: boolean = false
	let redirectUrl: string = ''

	data &&
		Object.values(data).forEach((suggestion: SuggestionsType) => {
			/* Search Driven Redirect? */
			if (onlyRedirect) {
				const searchRedirect: string = suggestion.c_searchRedirect || ''
				if (searchRedirect && !redirectFound) {
					redirectFound = true
					redirectUrl = searchRedirect
				}
			} else {
				/* Catalog suggestions? */
				if (suggestion.categories) {
					suggestion.categories.forEach((category) => {
						const isCollection: boolean = skipCollectionPrefixes.some(
							(prefix) => category.id.indexOf(prefix) > -1
						)
						if (!isCollection) {
							uniqueTerms = addUniqueTerm(
								uniqueTerms,
								category.name,
								category.parentCategoryName || ''
							)
						}
					})
				}

				/* Dictionary/Index phrases/terms? */
				if (suggestion.suggestedPhrases) {
					suggestion.suggestedPhrases.forEach((phrase) => {
						uniqueTerms = addUniqueTerm(uniqueTerms, phrase.phrase, '')
					})
				}
				if (suggestion.suggestedTerms) {
					suggestion.suggestedTerms.forEach((terms) => {
						if (terms && terms.terms) {
							terms.terms.forEach((term) => {
								uniqueTerms = addUniqueTerm(uniqueTerms, term.value, '')
							})
						}
					})
				}
			}
		})

	/* Return only redirect or unique terms */
	if (onlyRedirect) {
		return {
			found: redirectFound,
			url: redirectUrl,
		}
	} else {
		return uniqueTerms
	}
}

/* Fetch Helpers */
const getFullUrl = (path: string, queryString?: string) => {
	return (
		getAppOrigin() +
		apiBaseFolder +
		path +
		(queryString ? '?' + queryString : '')
	)
}
const getFetch = async (url: string) => {
	return await fetchSafely(url)
}

/* Named functions */
export const getBrandRecord = async (cgid: string) => {
	return await getFetch(getFullUrl('brand/' + cgid + '/'))
}
export const getBrandInfoRecord = async (cgid: string) => {
	return await getFetch(getFullUrl('brand-info/' + cgid + '/'))
}
export const getContentAsset = async (cid: string) => {
	return await getFetch(getFullUrl('asset/' + cid + '/'))
}
export const getContentAssetBundle = async (
	templateId: string,
	pageId?: string
) => {
	return await getFetch(
		getFullUrl('page/' + templateId + '/' + (pageId ? pageId + '/' : ''))
	)
}
export const getProductRecord = async (
	pid: string,
	queryString?: string,
	pidWithFamily?: string
) => {
	let data = (await getFetch(
		getFullUrl('product/' + pid + '/', queryString)
	)) as ScapiProduct | false

	/* Edge case for original productId with familyId prefix */
	if (data && data.status == 404 && pidWithFamily) {
		data = (await getFetch(
			getFullUrl('product/' + pidWithFamily + '/', queryString)
		)) as ScapiProduct | false
	}

	return data
}
export const getProductSearchRecord = async (
	cgid: string,
	queryString?: string
) => {
	return (await getFetch(getFullUrl('products/' + cgid + '/', queryString))) as
		| SearchResponse
		| false
}
export const getCategoryRecord = async (cgid: string) => {
	return await getFetch(getFullUrl('category/' + cgid + '/'))
}
export const getCustomObjectRecord = async (
	objectType: string,
	cgid: string
) => {
	return await getFetch(getFullUrl('object/' + objectType + '/' + cgid + '/'))
}
export const getEinsteinSearchRecord = async (queryString?: string) => {
	return await getFetch(getFullUrl('einstein/', queryString))
}
export const getSuggestions = async (
	queryString?: string,
	returnRedirects?: boolean
) => {
	return await getFetch(
		getFullUrl(
			'suggestions/',
			queryString + (returnRedirects ? '&redirects=true' : '')
		)
	)
}
export const getVideoRecommendation = async (productId: string) => {
	return await getFetch(getFullUrl('video/' + productId + '/'))
}
export const getRecommendedArticles = async (productId: string) => {
	return await getFetch(getFullUrl(`recommended-articles/${productId}/`))
}
export const getRecommendedStores = async (postalCode: string) => {
	return await getFetch(getFullUrl('stores/vicinity/' + postalCode + '/'))
}
