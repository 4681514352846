import React from 'react'
import { Helmet } from 'react-helmet'
import Script from '@core/script'

/* Reset Styles */
import oneTrustStyles from '!raw-loader!./OneTrust.css'

const OneTrustServer = ({
	oneTrustId,
}: {
	oneTrustId: string
}): React.ReactElement => {
	return (
		<>
			<Helmet>
				<style id={`css-OneTrust`}>{oneTrustStyles}</style>
			</Helmet>
			{oneTrustId && (
				<Script
					type={`text/javascript`}
					src={`https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`}
					data-domain-script={oneTrustId}
				/>
			)}
			<script
				type={`text/javascript`}
				dangerouslySetInnerHTML={{ __html: `function OptanonWrapper() {}` }}
			/>
		</>
	)
}

export default OneTrustServer
