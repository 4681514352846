/* Local Types */
interface useHeaderHeightReturnType {
	(offset?: number, expectMiniHeader?: boolean): number
}

export const useHeaderHeight = (): useHeaderHeightReturnType => {
	return (offset?: number, expectMiniHeader?: boolean): number => {
		let ret: number = 0
		const safeOffset: number = offset || 0

		/* Expected possible headers for desktop and mobile */
		const isMobile: boolean = window.innerWidth < 1200
		const headersToCheck = isMobile
			? ['mobile-banner']
			: ['mini-header', 'desktop-banner']

		headersToCheck.forEach((selector) => {
			if (!ret) {
				const element = document.querySelector(
					`.${selector}`
				) as HTMLElement | null
				if (element) {
					const styles: CSSStyleDeclaration = window.getComputedStyle(element)
					if (styles) {
						/* If the element is displayed and visible, get the height */
						if (
							expectMiniHeader ||
							(styles.getPropertyValue('display') !== 'none' &&
								styles.getPropertyValue('opacity') !== '0')
						) {
							ret =
								(element.clientHeight ||
									element.offsetHeight ||
									element.scrollHeight ||
									0) + safeOffset
							if (ret < 0) {
								ret = 0
							}
						}
					}
				}
			}

			/* No header yet, but expected? */
			if (!ret && expectMiniHeader) {
				ret = (isMobile ? 103 : 71) + safeOffset
			}
		})

		return ret
	}
}
