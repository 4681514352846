import React, { useState, lazy, Suspense } from 'react'
import PropTypes from 'prop-types'

/* Components */
import BrandLogo from '@components/logo/BrandLogo'

/* Lazy load Sentry */
const LazyLoadSentry = lazy(() => import('./LazyLoadSentry'))

const ErrorPage = (props) => {
	const { resetErrorBoundary, stack, message } = props

	/* Show stack trace */
	const [showStack, setShowStack] = useState(false)
	const canShowStack = stack || message ? true : false

	/* Styles - skip webpack to ouput raw css asap */
	const getStyles = () => {
		return `
            .section__img, img {
                display: block;
                margin: 0 auto;
            }
            iframe, .grecaptcha-badge, #onetrust-consent-sdk, .subscribe-modal {
                display: none;
                visibility: hidden;
            }
            .section {
                margin: 48px auto 0 auto;
                padding: 16px;
                width: 100%;
                text-align: center;
            }
            .section__h2 {
                margin-top: 16px;
                font-size: 2.375rem;
                font-weight: 400;
                letter-spacing: -0.0475rem;
            }
            .section__body {
                font-size: 1rem;
                line-height: 1.5;
                margin-top: 16px;
            }
            .section__stack {
                margin-top: 16px;
                padding: 16px;
                background-color: #f5f5f5;
                border-radius: 4px;
                overflow: auto;
                text-align: left;
            }
            .section__stack div {
                font-size: 1rem;
                line-height: 1.5;
            }
            .section__stack pre {
                font-size: 0.7rem;
                line-height: 1rem;
            }
            .section__btn {
                margin-top: 16px;
                padding: 8px 16px;
                background-color: #fff;
                color: #000;
                border: 1px solid #000;
                border-radius: 4px;
            }
        `
	}

	return (
		<React.Fragment>
			<Suspense fallback={null}>
				<LazyLoadSentry stack={stack} message={message} />
			</Suspense>
			<style>{getStyles()}</style>
			<section className={`section`}>
				<div className={`section__img`}>
					<BrandLogo variant={`181x102`} />
				</div>
				<h2 className={`section__h2`}>{`Something Went Wrong`}</h2>
				<div className={`section__body`}>
					<p
						onClick={
							canShowStack
								? () => {
										setShowStack(true)
								  }
								: undefined
						}
					>
						{`We're sorry, an unexpected error occurred. Our team has been notified and is working on it.`}
					</p>
					{resetErrorBoundary && (
						<button
							onClick={resetErrorBoundary}
							className={`section__btn notranslate`}
						>
							Try again
						</button>
					)}
				</div>
			</section>
			{canShowStack && (
				<section
					className={`section__stack`}
					style={{
						display: showStack ? 'block' : 'none',
					}}
				>
					{message && <div>{message}</div>}
					{stack && <pre>{stack}</pre>}
				</section>
			)}
		</React.Fragment>
	)
}

ErrorPage.propTypes = {
	skipSentry: PropTypes.bool,
	stack: PropTypes.string,
	message: PropTypes.string,
	status: PropTypes.number,
	resetErrorBoundary: PropTypes.func,
}

export default ErrorPage
