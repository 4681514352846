/* eslint-disable @typescript-eslint/no-explicit-any */

/* Polyfill for crypto.randomUUID */
export const randomUUID = (): string => {
	if (crypto && 'randomUUID' in crypto) {
		return crypto.randomUUID()
	} else {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
			/[xy]/g,
			function (c) {
				const r = (Math.random() * 16) | 0,
					v = c == 'x' ? r : (r & 0x3) | 0x8
				return v.toString(16)
			}
		)
	}
}

/* Add random UUID to array of objects */
export const arrayWithRandomUUID = (payload: any[]): any[] => {
	if (payload && Array.isArray(payload)) {
		payload = payload.map((item) => {
			return {
				...item,
				id: item.id || randomUUID(),
			}
		})
	}
	return payload
}

/* Add random UUID to object records */
export const objWithRandomUUID = (obj: object[]): object[] => {
	return obj.map((item: object) => {
		return {
			...item,
			uuid: randomUUID(),
		}
	})
}
