const config = {
	domain: 'https://www.the1916company.com/',
	domainNoPath: 'https://www.the1916company.com',
	siteName: 'The 1916 Company',
	ogLogo: 'https://the1916company.imgix.net/1916_og_logo.png',
}

export const domain = config.domain as string
export const domainNoPath = config.domainNoPath as string
export const siteName = config.siteName as string
export const ogLogo = config.ogLogo as string

export default config
