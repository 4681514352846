import { useState, useEffect, useRef } from 'react'

/* Debounce a setTimeout */
export const useDebounce = (delay: number) => {
	const timerId = useRef<NodeJS.Timeout | null>(null)

	/* Stop current timer */
	const clearTimerId = () => {
		if (timerId.current) {
			clearTimeout(timerId.current)
			timerId.current = null
		}
	}

	/* Clear timer on unmount */
	useEffect(() => {
		return clearTimerId
	}, [])

	/* Return debounced function call */
	return function (fn: () => void) {
		/* Clear new timer */
		clearTimerId()
		timerId.current = setTimeout(() => {
			clearTimerId()
			fn()
		}, delay)
	}
}

/* Check if callBack is attempted 2+ times in a short period */
export const useAntiFlood = (
	def?: boolean
): [boolean, (val: boolean) => void] => {
	/* Anti-flood state */
	const [flooded, setFlooded] = useState<boolean>(def || false)
	const debounce = useDebounce(50)

	/* Return anti-flood function call */
	return [
		flooded,
		(val: boolean) => {
			debounce(() => setFlooded(val))
		},
	]
}

export default useDebounce
