/* Shared Types */
import { ColVars } from '@lib/types/Grid.models'
import { getPlaceholder } from '../../core/image'

/* Local Types */
interface imgixConfigType {
	useGradent: boolean
	params: string
	paramsTrim: string
	padTrim: boolean
	paramsSquare: string
	cdn: string
	removeBG: string
	mark: string
	gradient: string
	overlay: string
	quality: number
	buckets: {
		[key: string]: [string, string]
	}
	breakPoints: {
		[key: string]: number
	}
	strapi: {
		expectedHost: string
		expectedFolder: string
	}
}

export const clearApply: { [key: string]: string[] } = {
	'pre-owned': ['bvlgari'],
}

/* Config */
const imgixConfig: imgixConfigType = {
	useGradent: false,
	params: '?auto=format,compress',
	paramsTrim: '&fit=fill&fill=solid&trim=auto',
	padTrim: true,
	paramsSquare: '&fit=fill&fill=solid',
	cdn: 'https://govberg-sfcc.imgix.net',
	removeBG: 'bg-remove=true',
	mark: 'mark=',
	gradient: '/posted-product-images/638410133458673339_greygradient.png',
	overlay:
		'dpr=2&auto=format&mark-scale=100&mark-align=bottom-center&mark-pad=0&fm=png&',
	quality: 100,
	buckets: {
		'strapi-media': [
			'valuable-serenity-80e6001bd0.media.strapiapp.com',
			'the1916company.imgix.net',
		],
		strapi: [
			'valuable-serenity-80e6001bd0.strapiapp.com',
			'the1916company.imgix.net',
		],
		the1916company: [
			'the1916company-imgix.s3.us-east-2.amazonaws.com',
			'the1916company.imgix.net',
		],
		'watchbox-product-cdn': [
			'posted-product-images.s3.amazonaws.com',
			'watchbox-product-cdn.imgix.net',
		],
		'govberg-sfcc': ['govberg-sfcc.s3.amazonaws.com', 'govberg-sfcc.imgix.net'],
	},
	breakPoints: {
		base: 0,
		sm: 576,
		md: 768,
		lg: 1024,
		xl: 1200,
		xxl: 1400,
	},
	strapi: {
		expectedHost: 'the1916company.imgix.net',
		expectedFolder: 'cms',
	},
}

/* Helper */
const imgixHelper = {
	buckets: function (
		url: string,
		baseUrl: boolean,
		dimensions?: string
	): string {
		if (url && typeof url === 'string') {
			if (baseUrl) {
				url = url.indexOf('?') > -1 ? url.split('?')[0] : url
			}
			if (url.indexOf('https://') > -1) {
				const urlParts = url.split('https://')
				if (urlParts.length > 2) {
					url = 'https://' + urlParts[urlParts.length - 1]
				}
			}
			let swapped = false
			Object.entries(imgixConfig.buckets).forEach(([, [aws, imgix]]) => {
				if (!swapped) {
					if (url.indexOf(aws) > -1) {
						swapped = true
						url = url.replace(new RegExp(aws, 'g'), imgix)
					}
				}
			})

			if (dimensions) {
				url +=
					(url.indexOf('?') > -1 ? '&' : '?') +
					'auto=format,compress&' +
					dimensions
			}
		}
		return url
	},
	expected: (url: string): string => {
		const { expectedHost, expectedFolder } = imgixConfig.strapi
		if (
			url &&
			typeof url === 'string' &&
			url.indexOf(expectedHost) > -1 &&
			url.indexOf('/' + expectedFolder + '/') == -1
		) {
			const [host, path] = url.split(expectedHost, 2)
			/*
			const regexp = /_([a-z0-9]{8,})\.(jpg|jpeg|png|gif|webp|tiff|bmp|svg)/;
			const newPath: string = path.replace(regexp, '.$2').replace(/ /g, '-');
            */
			const newUrl: string = host + expectedHost + '/' + expectedFolder + path
			return newUrl
		} else {
			return url
		}
	},
	params: function (
		url: string,
		width?: number,
		quality?: number,
		variant?: string,
		square?: boolean,
		padMore?: boolean
	): string {
		/* Also update mark image? */
		if (url.indexOf(imgixConfig.mark) > -1) {
			let markURL: string = url.toString()
			if (width) {
				markURL = markURL
					.replace(/w=(\d+)/g, 'w=' + width)
					.replace(/h=(\d+)/g, 'h=' + width)
				markURL = markURL
					.replace(/w%3D(\d+)/g, 'w%3D' + width)
					.replace(/h%3D(\d+)/g, 'h%3D' + width)
			} else {
				markURL = markURL.replace(/w=(\d+)/g, '').replace(/h=(\d+)/g, '')
				markURL = markURL.replace(/w%3D(\d+)/g, '').replace(/h%3D(\d+)/g, '')
			}
			return markURL
		} else {
			let useTrim: string = ''
			const useWidth: number = width ? width * 2 : 2500
			const useRemoveBG: string =
				variant && ['gray', 'trim', 'clear'].includes(variant)
					? '&' + imgixConfig.removeBG
					: ''
			const useRemoveSemiBG: string =
				variant && ['clear'].includes(variant)
					? '&bg-remove-semi-transparency=false'
					: ''
			const useQuality: string = '&q=' + (quality || imgixConfig.quality)
			if (variant == 'trim') {
				const padParams: number[] = padMore ? [150, 333, 333] : [150, 20, 100]
				const pad: number = Math.floor((useWidth / 2500) * padParams[0])
				const padRight: number = Math.floor((useWidth / 2500) * padParams[1])
				const padLeft: number = Math.floor((useWidth / 2500) * padParams[2])
				useTrim =
					imgixConfig.paramsTrim +
					(imgixConfig.padTrim
						? '&pad=' + pad + '&pad-left=' + padLeft + '&pad-right=' + padRight
						: '') +
					'&w=' +
					useWidth +
					'&h=' +
					useWidth
			} else {
				useTrim =
					'&w=' +
					useWidth +
					(square ? '&h=' + useWidth + imgixConfig.paramsSquare : '')
			}

			return (
				(url.indexOf('?') > -1 ? url.split('?')[0] : url) +
				imgixConfig.params +
				useRemoveBG +
				useRemoveSemiBG +
				useTrim +
				useQuality
			)
		}
	},
	vw: function (cols: number): string {
		return (100 / cols).toString() + 'vw'
	},
	sizes: function (cols: ColVars): string {
		const imageSizes: string[] = []
		Object.keys(cols).forEach((key: string) => {
			if (key !== 'base' && key in imgixConfig.breakPoints) {
				const size: number = imgixConfig.breakPoints[key]
				if (size) {
					const vw = cols[key] as number
					imageSizes.push(`(min-width: ${size}px) ${calculateVW(vw)}`)
				}
			}
		})
		if (cols.base) {
			const vw = cols.base as number
			imageSizes.push(`${calculateVW(vw)}`)
		}
		return imageSizes.join(', ')
	},
	gradient: function (url: string, variant: string): string {
		const baseURL: string = (
			url.indexOf('?') > -1 ? url.split('?')[0] : url
		).toLowerCase()
		if (
			!baseURL.match(/\.jpg|\.jpeg|\.png|\.gif|\.webp|\.tif|\.bmp|\.svg|\.avif/)
		) {
			return this.dummy()
		} else if (url.indexOf(imgixConfig.cdn) > -1) {
			if (variant && ['gray', 'trim', 'clear'].includes(variant)) {
				if (imgixConfig.useGradent) {
					// Incoming raw URL
					const incomingURL: string =
						url + (url.indexOf('?') > -1 ? '&' : '?') + imgixConfig.removeBG

					// Get width and height from URL, if present and pass to wrapped URL
					const width: RegExpMatchArray | null = incomingURL.match(/w=(\d+)/)
					const height: RegExpMatchArray | null = incomingURL.match(/h=(\d+)/)
					const dimensions: string =
						width && height && width.length > 1 && height.length > 1
							? `w=${width[1]}&h=${height[1]}&`
							: ''

					// Wrapped URL to place incoming image on top of gradient
					const wrappedURL = `${imgixConfig.cdn}${
						imgixConfig.gradient
					}?${dimensions}${imgixConfig.overlay}${
						imgixConfig.mark
					}${encodeURIComponent(incomingURL)}`
					return wrappedURL
				} else {
					/* Remove background from original image */
					const incomingURL: string =
						this.params(url, undefined, undefined, variant) +
						'&' +
						imgixConfig.removeBG
					return incomingURL
				}
			} else if (variant === 'original') {
				/* Remove background from original image */
				const incomingURL: string =
					this.params(url) + '&' + imgixConfig.removeBG
				return incomingURL
			} else {
				return url
			}
		} else {
			return url
		}
	},
	removeBg: function (url: string): string {
		if (url.indexOf(imgixConfig.removeBG) > -1) {
			return url
		}
		return url + (url.indexOf('?') > -1 ? '&' : '?') + imgixConfig.removeBG
	},
	dummy: function (layout?: string, variant?: string): string {
		return getPlaceholder(layout, variant)
	},
}

/* Named functions */
export function idealImgixParams(
	url: string,
	width?: number,
	quality?: number,
	variant?: string,
	square?: boolean,
	padMore?: boolean
): string {
	return imgixHelper.params(url, width, quality, variant, square, padMore)
}
export function calculateVW(cols: number): string {
	return imgixHelper.vw(cols)
}
export function getGradient(url: string, variant: string): string {
	return imgixHelper.gradient(url, variant)
}
export function getDummyWatch(layout?: string, variant?: string): string {
	return imgixHelper.dummy(layout, variant)
}
export function calculateImgSizes(cols: ColVars): string {
	return imgixHelper.sizes(cols)
}

/**
 * Retrieve a function to be used as the loader prop for the Next Image component. Allows for additional Imgix params to be passed in.
 *
 */
export const imgixLoader = ({
	removeBg,
	variant,
	params,
}: {
	// Optional Imgix props
	removeBg?: boolean
	variant?: string
	params?: { [key: string]: string | number }
}) => {
	return ({
		src,
		width,
		quality,
	}: {
		// Next Image component props:
		src: string
		width: number
		quality?: number
	}) => {
		if (src.indexOf('dummy-watch') > -1) {
			return src
		}

		// Loader logic
		let url = imgixHelper.params(src, width, quality, variant)

		if (removeBg) {
			url = imgixHelper.removeBg(url)
		}
		if (params) {
			Object.entries(params).forEach(([key, value]) => {
				if (key && value) {
					url += `&${key}=${value.toString()}`
				}
			})
		}

		return imgixHelper.buckets(url, false)
	}
}

export const getImgixURL = (url: string, baseUrl?: boolean): string => {
	return imgixHelper.buckets(url, baseUrl || false)
}
export const getBaseImgixURL = (url: string): string => {
	return imgixHelper.buckets(url, true)
}
export const getExpectedImgixURL = (
	url:
		| string
		| {
				data: null
		  }
): string => {
	if (typeof url !== 'string') {
		return ''
	}
	return imgixHelper.expected(imgixHelper.buckets(url, true))
}
export const getImgixURLAtWidth = (url: string, width: number): string => {
	return imgixHelper.buckets(url, true, `w=${width}`)
}
export const getImgixURLAtHeight = (url: string, height: number): string => {
	return imgixHelper.buckets(url, true, `h=${height}`)
}
