const config = {
	supportedCurrencies: [
		{
			key: 'AED',
			name: 'AED',
			detail: 'United Arab Emirates Dirham',
			symbol: '',
			text: 'AED',
		},
		{
			key: 'CHF',
			name: 'CHF',
			detail: 'Swiss Franc',
			symbol: '',
			text: 'CHF',
		},
		{
			key: 'EUR',
			name: 'EUR',
			detail: 'Euro',
			symbol: '€',
			text: '',
		},
		{
			key: 'GBP',
			name: 'GBP',
			detail: 'Great British Pound',
			symbol: '£',
			text: '',
		},
		{
			key: 'HKD',
			name: 'HKD',
			detail: 'Hong Kong Dollar',
			symbol: '',
			text: 'HKD',
		},
		{
			key: 'SGD',
			name: 'SGD',
			detail: 'Singapore Dollar',
			symbol: '',
			text: 'SGD',
		},
		{
			key: 'USD',
			name: 'USD',
			detail: 'United States Dollar',
			symbol: '$',
			text: '',
		},
	],
}

export const supportedCurrencies = config.supportedCurrencies as {
	key: string
	name: string
	detail: string
	symbol: string
	text: string
}[]

export default config
