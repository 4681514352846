import { useEffect } from 'react'
import { usePathname } from '@core/navigation'

/* Hooks */
import { useStateChangeAttempt } from './useStateChangeAttempt'

/* Helpers */
import { getCookie, setCookie } from '@helpers/cookieHelper'

/* Increment a cookie value by 1 each pathname change */
export const useIncrementCookie = (cookieName: string, max?: number): void => {
	const pathname = usePathname()

	useEffect(() => {
		const currentCount = parseInt(getCookie(cookieName) || '0') || 0
		if (max && currentCount >= max) {
			return
		}

		setCookie(cookieName, (currentCount + 1).toString())
	}, [pathname])
}

/* Check for existence of a cookie (optionally with a specific value) */
export const useHasCookie = (
	cookieName: string,
	cookieValue?: string | number,
	everyPage?: boolean,
	isCounter?: boolean,
	skipListener?: boolean
): boolean => {
	const [match, setMatch] = useStateChangeAttempt(false)

	/* Dependencies */
	const cookieDeps: string[] = []
	if (typeof window !== 'undefined' && everyPage) {
		cookieDeps.push(window.location.pathname)
	}

	/* Listen for cookie changes from setCookie() */
	const listenEvents: string[] = ['cookie:set']

	useEffect(() => {
		/* Track Cookie */
		const checkCookies = (e?: CustomEvent) => {
			/* Matching cookie updated? */
			if (e && e.detail?.key !== cookieName) {
				return
			}

			const cookieVal = getCookie(cookieName)
			if (isCounter) {
				/* Counter is higher than max value? */
				if (
					typeof cookieValue === 'number' &&
					parseInt(cookieVal) >= cookieValue
				) {
					setMatch(true)
				}
			} else {
				if (cookieValue) {
					/* Cookie exists with value? */
					if (cookieVal === cookieValue) {
						setMatch(true)
					}
				} else if (cookieVal?.trim().length > 0) {
					/* Cookie exists with any value? */
					setMatch(true)
				}
			}
		}

		/* Track Events */
		const setEvents = (add?: boolean) => {
			if (!skipListener) {
				listenEvents.forEach((event) => {
					if (add) {
						window.addEventListener(event, checkCookies as EventListener)
					} else {
						window.removeEventListener(event, checkCookies as EventListener)
					}
				})
			}
		}

		checkCookies()
		setEvents(true)
		return () => {
			setEvents(false)
		}
	}, cookieDeps)

	return match as boolean
}
