import { useEffect } from 'react'

/* Global Config */
import { domainNoPath } from '@lib/config/global'

export const useEscapeBadDomain = () => {
	const allowedDomains: string[] = [
		'localhost',
		'the1916company.com',
		'mobify-storefront.com',
		'commercecloud.salesforce.com',
	]

	useEffect(() => {
		/* Client side? */
		if (typeof window !== 'undefined') {
			/* If domain is not allowed, escape to production */
			const clientDomain: string = window.location.hostname
			if (
				allowedDomains.some(
					(allowedDomain) => clientDomain.indexOf(allowedDomain) > -1
				) === false
			) {
				window.location.href = `${domainNoPath}/?utm_medium=referral&utm_campaign=proxy&utm_source=${encodeURIComponent(
					clientDomain
				)}`
				return
			}
		}
	}, [])
}
