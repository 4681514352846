import React from 'react'
import Image from '@core/image'

/* Helpers */
import { routeBrandInfo } from '@helpers/routeHelper'

/* Components */
import SwitchLink from '@components/seo/SwitchLink'

const PatekPlaque = ({
	callbackHandler,
	variant,
}: {
	callbackHandler?: () => void
	variant: 'mobile' | 'desktop'
}): React.ReactElement => {
	/* Base Image */
	const baseImage =
		'https://govberg-sfcc.imgix.net/logos/PatekPhilippe_authorized_retailer_Large_500pxl.webp?auto=format,compress'

	/* Ideal Image Dimensions */
	const width = 119
	const height = 70

	/* Optimal Image */
	const imgSrc = [1, 2, 3]
		.map((dpr) => {
			return `${baseImage}&w=${width}&dpr=${dpr} ${dpr}x`
		})
		.join(',')

	/* Empty Image */
	const emptyImg =
		'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'

	/* Inline Styles */
	const styles = {
		container: {
			width: width + 'px',
			height: height + 'px',
			backgroundColor: 'white',
		},
		img: {
			width: width + 'px',
			maxWidth: width + 'px',
			height: height + 'px',
			maxHeight: height + 'px',
			cursor: 'pointer',
		},
	}

	return (
		<div style={styles['container']}>
			<SwitchLink
				onClick={callbackHandler}
				href={routeBrandInfo('patek-philippe')}
				data-wahfont={`13`}
				role={`link`}
			>
				<picture>
					<source
						srcSet={variant === 'mobile' ? imgSrc : emptyImg}
						media={`(max-width: 1199.98px)`}
					/>
					<source
						srcSet={variant === 'desktop' ? imgSrc : emptyImg}
						media={`(min-width: 1200px)`}
					/>
					<Image
						src={`${baseImage}&w=${width}`}
						style={styles['img']}
						width={width}
						height={height}
						alt={`Patek Philippe`}
					/>
				</picture>
			</SwitchLink>
		</div>
	)
}

export default PatekPlaque
