/* Hooks */
import {
	useAccountValues,
	RegisteredType,
} from '@hooks/useAccountLazyLoadCallback'

/* Named functions */
export const useIsRegistered = (): boolean => {
	/* Account present? */
	const account = useAccountValues() as RegisteredType
	if (!account) {
		return false
	}

	/* Check truthy boolean */
	return !!account.registered
}
