import { useEffect, useState, useCallback } from 'react'

/* Helpers */
import { fetchSafely } from '@helpers/fetchHelper'
import { getSFRAEndpoint } from '@helpers/sfraHelper'

/* Global State */
import { setGlobalState, getGlobalState } from '@state/global'

/* Hooks */
import { sendGTAGObject } from '@helpers/hooksHelper'
import { useDetectHuman } from '@hooks/useDetectHuman'

/* Local Types */
export type AccountVarsType =
	| {
			vars?: {
				AccountSFID?: string | null
			}
	  }
	| false
export type AccountReturnType = AccountVarsType | undefined
export type RegisteredType =
	| {
			registered?: boolean
	  }
	| false
export type AccountVars =
	| {
			accountVars?: {
				firstName?: string
				lastName?: string
				email?: string
				userCountry?: string
				userPostalCode?: string
				userCountryCode?: string
				phoneHome?: string
			}
	  }
	| false

/* Constants */
const endpoint: string = 'Account-LazyLoad'
const eventKey: string = 'account:updated'

/* Local Helpers */
const getCurrent = () => {
	return getGlobalState()?.account as AccountReturnType
}

/* Fetch Account Data from Global State */
export const useAccountValues = () => {
	/* Local State */
	const [account, setAccount] = useState<AccountReturnType>(
		getGlobalState()?.account as AccountReturnType
	)

	/* Fetch Global State on update */
	useEffect(() => {
		/* Set Local State */
		const updateAccount = () => {
			setAccount(getCurrent())
		}

		/* Event Listener */
		updateAccount()
		window.addEventListener(eventKey, updateAccount)
		return () => {
			window.removeEventListener(eventKey, updateAccount)
		}
	}, [])

	return account
}

/* Manage Account Data in Global State */
export const useAccountLazyLoadCallback = (
	callBack?: (data: AccountVars) => void,
	skipFetch?: boolean
): [() => AccountReturnType] => {
	const isHuman = useDetectHuman()

	/* Trigger Update */
	const triggerUpdate = () => {
		const data = getGlobalState()?.account

		/* Trigger Event */
		window.dispatchEvent(new CustomEvent(eventKey, { detail: data }))

		/* Callback */
		callBack && callBack(data as AccountVars)
	}

	/* Call once per session */
	const fetchAccount = useCallback(async () => {
		if (isHuman && !skipFetch) {
			const data = (await fetchSafely(
				getSFRAEndpoint(endpoint)
			)) as AccountVarsType
			if (data) {
				if (data.vars?.AccountSFID) {
					sendGTAGObject('set', { user_id: data.vars.AccountSFID })
				}

				/* Save Account Data */
				setGlobalState({ account: data })

				/* Trigger Callback */
				triggerUpdate()
			}
		} else {
			triggerUpdate()
		}
	}, [isHuman, skipFetch])

	/* Initialize */
	useEffect(() => {
		fetchAccount()
	}, [fetchAccount])

	/* Return function to fetch current state */
	return [getCurrent]
}
