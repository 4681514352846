import React from 'react'
import { Helmet } from 'react-helmet'

/* Config for Preloaded Fonts  */
const fontHost: string = 'https://the1916company.imgix.net'
const fontDir: string = '/fonts/'
const fonts: {
	[key: string]: string
} = {
	light: `${fontHost}${fontDir}F37Blanka-Light.woff2`,
	regular: `${fontHost}${fontDir}F37Blanka-Regular.woff2`,
	medium: `${fontHost}${fontDir}F37Blanka-Medium.woff2`,
}

/* Local Helpers */
const minifyStyle = (style: string): string => {
	return style
		.replace(/\s\s+/g, ' ')
		.replace(/(\r\n|\n|\r)/gm, '')
		.replace(/: /g, ':')
		.replace(/; /g, ';')
		.replace(/{ /g, '{')
		.replace(/ {/g, '{')
		.replace(/} /g, '}')
		.replace(/ }/g, '}')
		.replace(/, /g, ',')
		.replace(/;}/g, '}')
		.trim()
}

/* Local Types */
interface FontsProps {
	includeCaslon?: boolean
	preLoadFonts?: boolean
}

/* Inline CSS */
const fontCSS: string = `
        @font-face {
            font-display: swap;
            font-family: \'Caslon Text Regular Condensed\';
            font-style: normal;
            font-weight: 400;
            src:
                url(${fontHost}${fontDir}F37CaslonText-RegularCondensed.woff2) format(\'woff2\'),
                url(${fontHost}${fontDir}F37CaslonText-RegularCondensed.woff) format(\'woff\');
        }

        @font-face {
            font-display: swap;
            font-family: \'Blanka Expanded\';
            font-style: normal;
            font-weight: 400;
            src:
                url(${fontHost}${fontDir}F37Blanka-RegularExpanded.woff2) format(\'woff2\'),
                url(${fontHost}${fontDir}F37Blanka-RegularExpanded.woff) format(\'woff\');
        }

        @font-face {
            font-display: swap;
            font-family: \'Blanka Expanded\';
            font-style: normal;
            font-weight: 500;
            src:
                url(${fontHost}${fontDir}F37Blanka-MediumExpanded.woff2) format(\'woff2\'),
                url(${fontHost}${fontDir}F37Blanka-MediumExpanded.woff) format(\'woff\');
        }

        @font-face {
            font-display: swap;
            font-family: Blanka;
            font-style: normal;
            font-weight: 300;
            src:
                url(${fontHost}${fontDir}F37Blanka-Light.woff2) format(\'woff2\'),
                url(${fontHost}${fontDir}F37Blanka-Light.woff) format(\'woff\');
        }

        @font-face {
            font-display: swap;
            font-family: Blanka;
            font-style: normal;
            font-weight: 400;
            src:
                url(${fontHost}${fontDir}F37Blanka-Regular.woff2) format(\'woff2\'),
                url(${fontHost}${fontDir}F37Blanka-Regular.woff) format(\'woff\');
        }

        @font-face {
            font-display: swap;
            font-family: Blanka;
            font-style: italic;
            font-weight: 400;
            src:
                url(${fontHost}${fontDir}F37Blanka-RegularItalic.woff2) format(\'woff2\'),
                url(${fontHost}${fontDir}F37Blanka-RegularItalic.woff) format(\'woff\');
        }

        @font-face {
            font-display: swap;
            font-family: Blanka;
            font-style: normal;
            font-weight: 500;
            src:
                url(${fontHost}${fontDir}F37Blanka-Medium.woff2) format(\'woff2\'),
                url(${fontHost}${fontDir}F37Blanka-Medium.woff) format(\'woff\');
        }
`

const Fonts = ({
	includeCaslon,
	preLoadFonts,
}: FontsProps): React.ReactElement => {
	if (includeCaslon) {
		fonts.caslon = `${fontHost}${fontDir}F37CaslonText-RegularCondensed.woff2`
	}

	return (
		<Helmet>
			{preLoadFonts && (
				<>
					<link rel="preconnect" href={fontHost} />
					{Object.keys(fonts).map((key) => (
						<link
							key={key}
							id={`font-${key}`}
							rel="preload"
							href={fonts[key]}
							as="font"
							fetchpriority="high"
							type="font/woff2"
							crossOrigin={``}
						/>
					))}
				</>
			)}
			<style id={`fonts`}>{minifyStyle(fontCSS)}</style>
		</Helmet>
	)
}

export default Fonts
