import React, { useState, useEffect, useRef } from 'react'

/* Wishlist */
export { useCustomerWishlist } from './useCustomerWishlist'

/* Hooks */
import { useStateChangeAttempt } from '@lib/hooks/useStateChangeAttempt'

/* Local Types */
type dataLayerArgs = unknown[]
declare const window: {
	gmo: { [key: string]: unknown } // Google Marketing Platform Object
	dataLayer: dataLayerArgs // Google Tag Manager Data Layer
	gtag: Gtag.Gtag // Google Tag
} & Window
type paramType = string | boolean | number
type cbType = (e?: Event) => void
interface paramsType {
	[key: string]: paramType
}

/* Initialize dataLayer[] and gmo[] to avoid console errors */
export const safeStart = () => {
	window.gmo = window.gmo || {}
	window.dataLayer = window.dataLayer || []
}

/* Trigger initial gtm load */
export function sendGTMLoad() {
	const [loaded, setLoaded] = useState<boolean>(false)
	useEffect(() => {
		if (!loaded) {
			safeStart()
			window.dataLayer.push({
				'gtm.start': new Date().getTime(),
				event: 'gtm.js',
			})
			setLoaded(true)
		}
	})
}

/* Push data to dataLayer['event', event, optional data] */
/* Usage: sendGTAGEvent('event_name', { event_label: 'string' ... }); */
export function sendGTAGEvent(id: string, data?: unknown): void {
	if (data) {
		if ((data as paramsType).ecommerce) {
			sendDataLayer({ ecommerce: undefined })
		}
		sendDataLayer({ event: id, ...data })
	} else {
		sendDataLayer({ event: id })
	}
}

/* Push non-event data to dataLayer */
/* Usage: sendGTAGObject('set', { user_id: 'string' }); */
export function sendGTAGObject(
	key: string,
	object: { [key: string]: string | number }
): void {
	if (key && object) {
		safeStart()
		window.dataLayer.push([key, object])
	}
}

/* Append to DataLayer */
/* Usage: sendDataLayer({ currency: 'string' ... }); */
export function sendDataLayer(
	data:
		| { [key: string]: string | number | undefined }
		| string[]
		| undefined
		| unknown
): void {
	safeStart()
	window.dataLayer.push(data)
}

/* Trigger page_view and historyChange events on page navigation */
export function useGTAGEvent(pathname?: string, searchParams?: string): void {
	const skipParams: string[] = ['lang', 'cache']

	useEffect(() => {
		if (typeof window.gtag !== 'undefined') {
			/* Clean parameters before sending to GTAG Event */
			const cleanParams: paramsType = {}
			let cleanQuery: string = ''

			if (searchParams) {
				new URLSearchParams(searchParams).forEach((value, key) => {
					/* Must have:
                        - value defined
                        - non-encoded key
                        - alphanumeric key
                        - key not in skipParams
                    */
					if (
						value &&
						key &&
						key.indexOf('%') == -1 &&
						key.match(/[a-zA-Z0-9]/i) &&
						skipParams.includes(key) === false
					) {
						cleanParams[key] = value.toString()
					}
				})
				cleanQuery = new URLSearchParams(
					cleanParams as unknown as URLSearchParams
				).toString()
			}

			/* Location with bad params removed */
			const scrubbedPath: string =
				pathname + (cleanQuery ? '?' + cleanQuery : '')

			sendGTAGEvent('page_view', {
				page_location: scrubbedPath,
			})
			sendGTAGEvent('gtm.historyChange')
		}
	}, [pathname, searchParams])
}

/* Initialize Google Tag Manager with dataLayer[] and gtag() */
export function useDataLayer(
	gtmID: string
): [string, React.Dispatch<React.SetStateAction<string>>] {
	const [usedGTMID, setUsedGTMID] = useStateChangeAttempt(gtmID)
	useEffect(() => {
		safeStart()
		window.gtag =
			window.gtag ||
			function (...args: dataLayerArgs) {
				window.dataLayer.push(args)
			}
		window.gtag('js', new Date())
		window.gtag('config', gtmID, {
			send_page_view: false,
		})
	}, [usedGTMID, gtmID])
	return [usedGTMID as string, setUsedGTMID]
}

export function useClickAway(cb: cbType, params?: paramType[]) {
	const ref = useRef(null) as React.MutableRefObject<null | HTMLElement>
	useEffect(() => {
		const handler = (e: Event): void => {
			const element = ref.current
			if (element && !element.contains(e.target as HTMLElement)) {
				try {
					cb.apply(null, [e])
				} catch (err) {
					console.error(err)
				}
			}
		}
		const events: string[] = ['mousedown', 'touchstart']
		events.forEach((event) => document.addEventListener(event, handler))
		return () => {
			events.forEach((event) => document.removeEventListener(event, handler))
		}
	}, params || [])

	return ref
}

export function useMediaQuery(query: string) {
	/* TODO:
        return true (match in client),
        false (no match in client),
        or null (server side to render placeholder)
    */
	const [matches, setMatches] = useStateChangeAttempt(null)

	useEffect(() => {
		const mediaQueryList = window.matchMedia(query)

		const handleChange = (): void => {
			setMatches(mediaQueryList.matches ? true : false)
		}

		mediaQueryList.addEventListener('change', handleChange)
		handleChange()

		return () => {
			mediaQueryList.removeEventListener('change', handleChange)
		}
	})

	return matches
}

export const useIsClient = () => {
	const [isClient, setIsClient] = useStateChangeAttempt(false)

	useEffect(() => {
		if (typeof window !== 'undefined') {
			if (!isClient) {
				setIsClient(true)
			}
		}
	}, [])

	return isClient
}

export const useIsLocalHost = () => {
	const [isLocalHost, setIsLocalHost] = useStateChangeAttempt(false)

	useEffect(() => {
		if (typeof window !== 'undefined') {
			if (!isLocalHost) {
				setIsLocalHost(window.location.hostname === 'localhost')
			}
		}
	})

	return isLocalHost
}

export const useIsLargeScreen = (): boolean => {
	return typeof window !== 'undefined' && window.innerWidth >= 1200
}
