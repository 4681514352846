/* Helpers */
import { randomUUID, objWithRandomUUID } from '../cryptoHelper'

/* Shared Types */
import { JSONObject } from '@lib/types/JSONObject.models'
import {
	retType,
	configType,
	RootCategory,
	Category,
	HookCatalog,
} from './navHelper.models'
import {
	NavigationData,
	CatalogItem,
	ExperienceItem,
	PlaqueItem,
} from '@lib/mock/nav.models'

/* Config */
import { specialTopLevel } from '@lib/config/watches'

/* Fixed Config */
const topLevelCategories: string[] = [
	'watches',
	'pre-owned',
	'rlx',
	'rlx-cpo',
	'patek-philippe',
	'jewelry',
]
const config: configType = {
	byBrandTitle: 'Featured Brands',
	collectionPrefix: 'collection-',
	collectionSuffix: '-collection',
	shopAll: 'shop-all',
	typeIDs: ['watches', 'pre-owned', 'jewelry'],
	typePrefixes: ['watches-', 'pre-owned-', 'patek-philippe-', 'jewelry-'],
	collectionTitle: 'By Collection',
	categoryTitle: 'By Category',
	tnscCompany: ' - The 1916 Company',
	allBrands: {
		name: 'View All Brands',
		key: 'all-brands',
	},
	brandConversion: {
		'rlx-cpo': 'rolex-certified-pre-owned',
		rlx: 'rolex',
	},
	maxSubCategories: 18, // Updated to 18 from 16 for CPO and then shaved back down for most categories by maxSectionItems in DesktopNav.tsx and shouldLimitMaxSectionItems in MobileMenu.tsx
	maxCollections: 6,
	prefixTopLevelBrands: {
		'patek-philippe': 'watches/',
	},
	suffixTopLevelBrands: {
		'patek-philippe': '/info',
	},
}

/* Local Mocks - Pending Data */
import {
	mockEditorials,
	mockSellAndTrade,
	mockExperience,
	mockPlaque,
} from './navHelper.mock'

/* Local Types */
interface HookCategories {
	name?: string
	key?: string
	uuid?: string
	img?: string | null
	asset?: string
	vals?: {
		name: string
		key: string
		uuid: string
		href?: string
	}[]
	categories?: RootCategory[]
}

/* Local Helpers */
const applyHook1916Categories = (
	ret: HookCategories[],
	data: RootCategory,
	topLevel: boolean
): HookCategories[] => {
	if (data && data.categories) {
		const { categories, c_subcategoryAsset, c_subcategoryJSON } = data
		categories.forEach((item: Category) => {
			const { id } = item
			if (id && (!topLevel || topLevelCategories.includes(id))) {
				const asset: string = c_subcategoryAsset
					? c_subcategoryAsset[id] || ''
					: ''
				const json: string =
					id === 'jewelry' && c_subcategoryJSON
						? c_subcategoryJSON[id] || ''
						: ''
				const data = hookDataCategory(
					item as unknown as JSONObject,
					id,
					asset,
					json
				)
				if (data) {
					ret.push(data)
				}
			}
		})
	}
	return ret
}

/* Local Hooks */
export const hook1916Categories = (
	data: RootCategory,
	secondaryData?: RootCategory
): HookCategories[] => {
	let ret: HookCategories[] = []
	if (data) {
		ret = applyHook1916Categories(ret, data, true)
	}
	if (secondaryData) {
		ret = applyHook1916Categories(
			ret,
			{ categories: [secondaryData] } as RootCategory,
			false
		)
	}
	return ret
}
export const hookNav = (
	catalog: HookCatalog,
	secondaryCatalog?: HookCatalog
): NavigationData => {
	const dataCatalogNav = hook1916Categories(
		catalog as unknown as RootCategory,
		secondaryCatalog as unknown as RootCategory
	) as CatalogItem[]

	/* Initial mock data */
	let mockEditorialsUse: CatalogItem = mockEditorials
	let mockSellAndTradeUse: CatalogItem = mockSellAndTrade

	if (catalog) {
		const { c_subcategoryAsset, c_subcategoryJSON } = catalog ?? {}

		/* JSON Content Asset Override */
		if (c_subcategoryJSON && typeof c_subcategoryJSON === 'object') {
			Object.keys(c_subcategoryJSON).forEach((key) => {
				if (c_subcategoryJSON[key] as string) {
					let json = {}
					try {
						json = JSON.parse(c_subcategoryJSON[key] as string)
					} catch (e) {
						console.error(e)
					}
					if (json) {
						switch (key) {
							case 'editorial':
								mockEditorialsUse = json
								break
							case 'sell-and-trade':
								mockSellAndTradeUse = json
								break
						}
					}
				}
			})
		}

		/* Asset Include Override */
		if (c_subcategoryAsset && typeof c_subcategoryAsset === 'object') {
			Object.keys(c_subcategoryAsset).forEach((key) => {
				if (c_subcategoryAsset[key]) {
					switch (key) {
						case 'editorial':
							mockEditorialsUse.asset = c_subcategoryAsset[key] as string
							break
						case 'sell-and-trade':
							mockSellAndTradeUse.asset = c_subcategoryAsset[key] as string
							break
					}
				}
			})
		}
	}

	/* Append non-catalog top level navs */
	dataCatalogNav.push(mockEditorialsUse as unknown as CatalogItem)
	dataCatalogNav.push(mockSellAndTradeUse as unknown as CatalogItem)

	const dataExperience: ExperienceItem[] =
		mockExperience as unknown as ExperienceItem[]
	const dataPlaque: PlaqueItem[] = mockPlaque as unknown as PlaqueItem[]

	const ret: NavigationData = {
		catalog: dataCatalogNav,
		experience: dataExperience,
		plaque: dataPlaque,
	}

	return ret
}
export const hookDataCategory = (
	data: JSONObject,
	navParent: string,
	asset?: string,
	json?: string
): JSONObject => {
	const {
		byBrandTitle,
		collectionPrefix,
		collectionSuffix,
		shopAll,
		typeIDs,
		typePrefixes,
		collectionTitle,
		categoryTitle,
		tnscCompany,
		allBrands,
		brandConversion,
		maxSubCategories,
		maxCollections,
		prefixTopLevelBrands,
		suffixTopLevelBrands,
	} = config

	/* Brand or Type */
	const isType: boolean = typeIDs.includes(navParent)
	const isTopLevel: boolean = specialTopLevel.includes(navParent)
	const isRolex: boolean = Object.keys(brandConversion).includes(navParent)
	const isPatek: boolean = navParent === 'patek-philippe'
	const isJewelry: boolean = navParent === 'jewelry'

	/* Collection prefix and suffix */
	const collectionPrefixUsed: string = isRolex ? '' : collectionPrefix
	const collectionSuffixUsed: string = isRolex ? '' : collectionSuffix

	/* Set verbiage based on Brand or Type */
	const byCollectionTitle: string = isJewelry
		? categoryTitle
		: isRolex || isType || isTopLevel
		  ? collectionTitle
		  : data.name?.toString() || ''
	const tnscSuffix: string = isRolex || isType || isTopLevel ? '' : tnscCompany
	const allBrandsLink: JSONObject | undefined = isType ? allBrands : undefined

	/* Get local vs SFCC brand id mapping (rlx = rolex) */
	const brandId: string = data.id?.toString() || ''
	const brandIdActual: string = brandConversion
		? brandConversion[brandId] || brandId
		: brandId

	/* Change top level nav link? */
	const suffixBrand: string =
		navParent in suffixTopLevelBrands ? suffixTopLevelBrands[navParent] : ''
	const prefixBrand: string =
		!suffixBrand && navParent in prefixTopLevelBrands
			? prefixTopLevelBrands[navParent]
			: ''

	/* Main Category */
	const ret: retType = {
		name: data.name?.toString() || '',
		key: brandIdActual,
		href: prefixBrand + brandIdActual + suffixBrand,
		uuid: randomUUID(),
		img: null,
		asset: asset,
		vals: [],
	}

	/* Split Subcategories by Collection and Brand */
	const byCollection: JSONObject[] = []
	const byBrand: JSONObject[] = []
	if (data.categories) {
		const categories = data.categories as JSONObject[]
		categories.forEach((item) => {
			const id = item.id?.toString()
			const name = item.name?.toString()
			if (id && name) {
				const maxItems = data.id === 'jewelry' ? 12 : maxSubCategories
				const c_isCollection = item.c_isCollection?.toString()
				const isPseudoCollection: boolean =
					id.indexOf('jewelry') == -1 &&
					(id.indexOf('collection-') > -1 || id.indexOf('-collection') > -1)
				const c_isMarketing = item.c_isMarketing?.toString()
				const isAll: boolean =
					(item.c_enforceAttribute as string)
						?.toString()
						.indexOf('special=all') > -1
						? true
						: false
				const isShopAll: boolean =
					id.indexOf(collectionPrefix + shopAll) > -1 ||
					id.indexOf(shopAll + collectionSuffix) > -1
						? true
						: false
				const isRolexCollection: boolean =
					id.indexOf('collection-discover-rolex') > -1 ? true : false
				if (c_isMarketing === 'true') {
				} else if (isAll || isShopAll || isRolexCollection) {
					if (byCollection.length < maxCollections) {
						byCollection.push({
							name: name,
							key: id,
							uuid: randomUUID(),
							href: '/..',
						})
					}
				} else if (c_isCollection === 'true') {
					if (byCollection.length < maxCollections) {
						byCollection.push({
							name: name,
							key: id,
							uuid: randomUUID(),
						})
					}
				} else if (isPseudoCollection && id.indexOf(collectionPrefix) > -1) {
					if (byCollection.length < maxCollections) {
						byCollection.push({
							name: name,
							key: id,
							uuid: randomUUID(),
							href: isAll
								? ''
								: collectionPrefixUsed + id.split(collectionPrefix)[1],
						})
					}
				} else if (isPseudoCollection && id.indexOf(collectionSuffix) > -1) {
					if (byCollection.length < maxCollections) {
						byCollection.push({
							name: name,
							key: id,
							uuid: randomUUID(),
							href: isAll
								? ''
								: id.split(collectionSuffix)[0] + collectionSuffixUsed,
						})
					}
				} else {
					if (byBrand.length < maxItems) {
						const byBrandNew: JSONObject = {
							name: name,
							key: id,
							uuid: randomUUID(),
						}
						let href: string | undefined = undefined
						if (id.indexOf(navParent + '-') == 0) {
							href = id.split(navParent + '-')[1]
						} else {
							typePrefixes.forEach((prefix) => {
								if (!href && id.indexOf(prefix) == 0) {
									href = id.split(prefix)[1]
								}
							})
							Object.keys(brandConversion).forEach((key) => {
								if (!href && id.indexOf(key + '-') == 0) {
									href = id.split(key + '-')[1]
								}
							})
						}
						if (href) {
							byBrandNew.href = href
						}
						byBrand.push(byBrandNew)
					}
				}
			}
		})
	}

	/* Set section titles */
	const collectionsTitle = byCollectionTitle + tnscSuffix
	const brandsTitle = isType ? byBrandTitle : ret.name
	const sectionTitles =
		isRolex || isPatek
			? {
					brands: collectionsTitle,
					collections: brandsTitle,
			  }
			: {
					collections: collectionsTitle,
					brands: brandsTitle,
			  }

	/* Any collections? */
	if (byCollection.length > 0) {
		ret.vals.push({
			name: sectionTitles.collections,
			uuid: randomUUID(),
			val: byCollection,
		})
	}

	/* Any brands? */
	if (byBrand.length > 0) {
		const byBrandObj: JSONObject = {
			name: sectionTitles.brands,
			uuid: randomUUID(),
			val: byBrand,
		}

		if (allBrandsLink) {
			byBrandObj.all = allBrands
		}
		ret.vals.push(byBrandObj)
	}

	/* Third nav for Jewelry */
	if (isJewelry && json) {
		let servicesNav: {
			name?: string
			vals?: JSONObject[]
		} = {}
		try {
			servicesNav = JSON.parse(json)
		} catch (e) {}
		if (servicesNav?.vals && servicesNav.vals.length > 0) {
			const byServicesObj: JSONObject = {
				name: servicesNav.name || '',
				uuid: randomUUID(),
				val: objWithRandomUUID(servicesNav.vals) as JSONObject[],
			}
			ret.vals.push(byServicesObj)
		}
	}

	return ret as unknown as JSONObject
}
