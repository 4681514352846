/*
Usage:
	import { routeHelper } from '@helpers/routeHelper'

	const pdpPath: string = routeHelper.route('pdp', 'sku')
	const plpPath: string = routeHelper.route('plp', 'cgid')
	const blogPath: string = routeHelper.route('blog', 'articleid')
	const homePath: string = routeHelper.route('home')
*/

/* Shared Types */
import { PLPDirsType } from '@components/watches/PLPType.models'

/* Local Types */
type idType = string | undefined
export type categoryType = { [key: string]: string[] }
interface routesType {
	[key: string]: string
}
interface getCollectionReturn {
	cgid: string
	cgidParent: string
	cgidType: string
	enforceAttribute: string
	isCollection: string
	isCollectionName?: string
}

export interface TradeUrlParams {
	brandId?: string
	brandName?: string
	familyId?: string
	familyName?: string
	image_url?: string
	refNumId?: string
	refNum?: string
	sku?: string
}

/* TODO - pull /fr, /de, etc. from the user's session */
const userLocale: string = 'default' // TODO - get this from the user's session

/* Defined routes that may result in non-standard urls */
const routes: routesType = {
	/* PDP Variations */
	pdp: 'watches',

	/* PLP Variations */
	plp: 'watches',
	wlp: 'watches',
	//ulp: 'pre-owned',
	jlp: 'jewelry',
	blp: 'rolex',
	clp: 'rolex-certified-pre-owned',

	/* All Pages */
	'all-brands': '',
	'all-watches': '',

	/* Other routes */
	root: '',
	home: '',
	jewelry: 'jewelry',
	'about-us': 'about-us',
	author: 'blog/authors',
	blog: 'blog',
	trade: 'trade-quote',
	brands: 'watches',
	rolex: 'rolex',
	shop: 'shop',

	/* Top Nav */
	results: 'search',
	search: 'search',
	locations: 'about-us/our-locations',
	contact: 'contact-us',
	account: 'account',
	login: 'login',
	wishlist: 'wishlist',
	bag: 'bag',

	/* Content Pages */
	page: '',
}

/* Collection Configs */
const collectionPrefixes: string[] = ['collection-', '-collection']
const collectionTypes: { [key: string]: string } = {
	'shop-all': '',
	'mens-watches': "gender=Men's|Mens|Male|Unisex",
	'womens-watches': 'gender=Ladies|Female|Unisex',
}
const collectionNames: { [key: string]: string } = {
	'shop-all': 'Shop All',
	'mens-watches': "Men's Watches",
	'womens-watches': "Women's Watches",
}
const collectionsPrefix: PLPDirsType = {
	'shop-all': '-collection-',
}

/* Configs */
import {
	plpTranslate,
	plpPrefix,
	plpSecondary,
	familyOverride,
} from '../../config/categories'

/* Config */
import {
	specialTopLevel,
	skuPrefix,
	prefixAdditions,
	rolexCPOPrefix,
	rolexBespokePrefix,
} from '../../config/watches'

/* Dummy directories for PLP/PDP */
const brandDir: string = 'brand'
const familyDir: string = 'family'

/* Local Helpers */
const scrubDoubleBrand = (brandId: string, id: string): string => {
	if (id.indexOf(`${brandId}-${brandId}-`) === 0) {
		return id.replace(new RegExp(`^${brandId}-${brandId}-`), `${brandId}-`)
	} else if (id.indexOf(`${brandId}-`) === 0) {
		return id.replace(new RegExp(`^${brandId}-`), '')
	} else {
		return id
	}
}

/* Helper object */
export const routeHelper = {
	sfccPrefix: function (layout: string, id: string): string {
		let ret = ''
		if (layout === id) {
			ret = ''
		} else if (id.indexOf(layout + '-') === 0) {
			ret = ''
		} else if (id in collectionsPrefix) {
			ret += layout + collectionsPrefix[id]
		} else if (layout in plpPrefix) {
			ret += plpPrefix[layout]
		}
		return ret
	},
	sfccPrefixes: function (): PLPDirsType {
		return plpPrefix
	},
	sfccSecondary: function (): string[] {
		return plpSecondary
	},
	removeSFCCPrefixes: function (dirs: string[], layout: string): string[] {
		if (dirs.length > 1) {
			for (let i = 1; i < dirs.length; i++) {
				dirs[i] = this.removeSFCCPrefix(plpPrefix, dirs[i], layout)
			}
		}
		return dirs
	},
	removeSFCCPrefix: function (
		prefixes: PLPDirsType,
		dir: string,
		layout: string,
		canTranslate?: boolean
	): string {
		if (canTranslate && dir && dir in plpTranslate) {
			dir = plpTranslate[dir]
		}
		const prefix: string = (prefixes[layout] as string) || ''
		return dir && prefix ? dir.replace(new RegExp(`^${prefix}`), '') : dir
	},
	removeSFCCPrefixSKU: function (sku: string, layout: string): string {
		if (layout in skuPrefix) {
			/* Legacy Jewelry Prefixes */
			if (sku.indexOf('ns-jn-') === 0 || sku.indexOf('ns-jp-') === 0) {
				return sku.replace('ns-', '')
			}
			return sku.replace(new RegExp(`^${skuPrefix[layout]}`), '')
		} else {
			return sku
		}
	},
	locale: function (userLocale: string): string {
		return userLocale === 'default' ? '/' : '/' + userLocale + '/'
	},
	root: function (): string {
		return ''
	},
	direct: function (
		sku: idType,
		categories: categoryType,
		actualSku?: string
	): string {
		let ret: string = ''

		/* Direct Routes */
		const isDirectRoutes: { [key: string]: string[] } = {
			'rlx-cpo': [routes.clp, rolexCPOPrefix],
			rlx: [routes.blp, rolexBespokePrefix],
		}

		Object.entries(isDirectRoutes).forEach(([layout, route]) => {
			if (!ret && categories && layout in categories) {
				const layoutCategories = categories[layout]
				if (layoutCategories.length > 1 && layoutCategories[0] === layout) {
					const useSku = (actualSku ? actualSku.toLowerCase() : sku) as string
					ret =
						route[0] +
						'/' +
						layoutCategories[1].replace(layout + '-', '') +
						'/' +
						useSku.replace(route[1], '') +
						'/'
				}
			}
		})

		return ret.trim()
	},
	pdp: function (
		sku: idType,
		categories: categoryType,
		layout: string,
		skipComplete?: boolean,
		actualSku?: string,
		allowVariantCanonical?: boolean
	): string {
		sku = sku || ''

		/* Locale prefix? */
		const ret: string = this.locale(userLocale)

		/* Bespoke/CPO Watch? */
		const isDirect: string = this.direct(sku, categories, actualSku)
		if (isDirect) {
			return ret + isDirect
		}

		/* Special top level category? */
		const isSpecialTopLevel: boolean = specialTopLevel.includes(layout)

		/* Ensure we have layout, brand, family, sku for folder path */
		let expectedDir: string[] = []
		if (layout && categories && Object.keys(categories).length > 0) {
			expectedDir = layout in categories ? categories[layout]?.slice(0, 3) : []
		}
		if (!skipComplete) {
			if (isSpecialTopLevel) {
				if (expectedDir.length == 0) {
					expectedDir.push(layout, familyDir)
				} else if (expectedDir.length == 1) {
					expectedDir.push(familyDir)
				}
			} else {
				if (expectedDir.length == 0) {
					expectedDir.push('watches', brandDir, familyDir)
				} else if (expectedDir.length == 1) {
					expectedDir.push(brandDir, familyDir)
				} else if (expectedDir.length == 2) {
					expectedDir.push(familyDir)
				}
			}
		}

		/* Remove sku prefix? */
		let expectedSku: string = this.removeSFCCPrefixSKU(sku, layout)

		/* Remove Brand prefix from family and/or sku? */
		if (isSpecialTopLevel) {
			if (expectedDir.length > 1) {
				expectedDir[1] = getRemovedFamilyId(expectedDir[1], expectedDir[0])
				if (!expectedDir[1]) {
					expectedDir[1] = familyDir
				}
				if (allowVariantCanonical) {
					expectedSku = getRemovedFamilyId(expectedSku, expectedDir[1])
				}
			}
		} else if (expectedDir.length > 2) {
			expectedDir[2] = getRemovedFamilyId(expectedDir[2], expectedDir[1])

			/* Mad exception */
			expectedDir[2] = expectedDir[2].replace('m-a-d-', '')

			if (!expectedDir[2]) {
				expectedDir[2] = familyDir
			}
			if (allowVariantCanonical) {
				expectedSku = getRemovedFamilyId(expectedSku, expectedDir[2])
			}
		}

		/* Remove layout prefixes? */
		expectedDir = this.removeSFCCPrefixes(expectedDir, layout)

		/* Build the path */
		return (
			ret +
			expectedDir.join('/') +
			'/' +
			(expectedSku ? encodeURIComponent(expectedSku) + '/' : '')
		)
	},
	plp: function (
		cgid: idType,
		layoutId?: string,
		parentId?: string,
		qParam?: string
	): string {
		cgid = cgid || ''
		layoutId = layoutId || routes.plp

		/* Layout, Brand, Family? */
		const expectedDir: string[] = [layoutId]
		if (cgid) {
			if (parentId === 'search') {
				expectedDir[0] = parentId
				expectedDir.push(cgid)
			} else {
				if (parentId && parentId !== layoutId) {
					expectedDir.push(parentId)
				}
				if (cgid !== layoutId && cgid !== parentId) {
					expectedDir.push(cgid)
				}
			}
		}

		/* Return Canonical PDP Path without a SKU Suffix and possible search query */
		return (
			this.pdp(
				'',
				{
					[layoutId]: expectedDir,
				},
				layoutId,
				true
			) + (qParam ? '?q=' + encodeURIComponent(qParam) : '')
		)
	},
	aboutUs: function (pid?: string): string {
		const ret: string = routes['about-us'] + '/' + (pid ? pid + '/' : '')
		return ret
	},
	blog: function (cid: idType, aid?: string): string {
		cid = cid || ''
		aid = aid || ''
		if (cid === 'home') {
			cid = ''
		}
		if (cid && cid.endsWith('.html')) {
			return `${routes.blog}/${cid}`
		} else if (aid) {
			return `${routes.author}/${aid}/${cid || 'all-articles'}/`
		} else {
			return `${routes.blog}${cid ? '/' + cid : ''}/`
		}
	},
	home: function (): string {
		return routes.home ? routes.home + '/' : ''
	},
	trade: function (params?: TradeUrlParams | string): string {
		let queryStr = ''
		if (typeof params === 'string') {
			queryStr = params
		} else {
			const query = new URLSearchParams()

			if (params) {
				for (const key in params) {
					if (params[key as keyof TradeUrlParams]) {
						query.set(key, params[key as keyof TradeUrlParams] as string)
					}
				}
			}

			queryStr = query.toString()
		}

		const ret: string = `${routes.trade}/${
			queryStr.length > 0 ? '?' + queryStr : ''
		}`
		return ret
	},
	brands: function (bid: idType): string {
		const ret: string = routes.brands + '/' + bid + '/'
		return ret
	},
	rolex: function (cgid?: idType): string {
		const ret: string = routes.rolex + '/' + (cgid ? cgid + '/' : '')
		return ret
	},
	results: function (key: string, id: string): string {
		return routes.results + '/?' + key + '=' + encodeURIComponent(id)
	},
	route: function (
		routeId: string,
		id?: string,
		query?: string | TradeUrlParams
	): string {
		const localePrefix = this.locale(userLocale)
		let ret: string = localePrefix
		if (routeId in routes) {
			switch (routeId) {
				case 'root':
					ret += this.root()
					break
				case 'plp':
					ret += this.plp(id, 'wlp')
					break
				case 'about-us':
					ret += this.aboutUs(id)
					break
				case 'blog':
					ret += this.blog(id, query as string)
					break
				case 'trade':
					ret += this.trade(query)
					break
				case 'brands':
					ret += this.brands(id)
					break
				case 'rolex':
					ret += this.rolex(id)
					break
				case 'home':
					ret += this.home()
					break
				case 'all-watches':
					ret += id + '/'
					break
				case 'all-brands':
					ret += id + '/' + routeId + '/'
					break
				case 'search':
					ret +=
						routeId +
						'/' +
						(id ? id + '/' : '') +
						(query ? '?q=' + encodeURIComponent(query as string) : '')
					break
				case 'results':
					ret += this.results(id || '', query as string)
					break
				case 'login':
					ret += routeId + '/' + (query || '')
					break
				case 'page':
					ret += id + '/'
					break
				case 'locations':
					ret +=
						routes[routeId] +
						'/' +
						(query ? query + '/' : '') +
						(id ? id + '/' : '')
					break
				default:
					ret += routes[routeId] + '/'
					if (id) {
						ret += id.replace(/^\//, '')
					}
					break
			}
		}
		return ret
	},
}

/* Named functions */
export function routeRoot(): string {
	return routeHelper.route('root')
}
export function routePDP(
	sku: string,
	categories: categoryType,
	layout: string,
	actualSku?: string,
	allowVariantCanonical?: boolean
): string {
	return routeHelper.pdp(
		sku,
		categories,
		layout,
		false,
		actualSku,
		allowVariantCanonical
	)
}
export function routePLP(
	cgid: string,
	layoutId: string,
	parentId?: string,
	qParam?: string
): string {
	return routeHelper.plp(cgid, layoutId, parentId, qParam)
}
export function routeAboutUs(pid?: string): string {
	return routeHelper.route('about-us', pid)
}
export function routeBlog(cid: string): string {
	return routeHelper.route('blog', cid)
}
export function routeAuthor(aid: string, cid?: string): string {
	return routeHelper.route('blog', cid, aid)
}
export function routeHome(): string {
	return routeHelper.route('home')
}
export function routeJewelry(): string {
	return routeHelper.route('jewelry')
}
export function routeTrade(params?: TradeUrlParams | string): string {
	return routeHelper.route('trade', undefined, params)
}
export function routeBrands(bid: string): string {
	return routeHelper.route('brands', bid)
}
export function routeBrandInfo(bid: string): string {
	return routeHelper.route('brands', bid) + 'info/'
}
export function routeRolex(cgid?: string): string {
	return routeHelper.route('rolex', cgid)
}
export function routeCart(): string {
	return routeHelper.route('cart')
}
export function routeAllBrands(layoutId: string): string {
	return routeHelper.route('all-brands', layoutId)
}
export function routeAllWatches(layoutId: string): string {
	return routeHelper.route('all-watches', layoutId)
}
export function routeSearch(layout?: string, query?: string): string {
	return routeHelper.route('search', layout, query)
}
export function routeLocations(id?: string, multiBrand?: boolean): string {
	return routeHelper.route(
		'locations',
		id,
		multiBrand ? 'multi-brand-showrooms' : ''
	)
}
export function routeContact(): string {
	return routeHelper.route('contact')
}
export function routeAccount(): string {
	return routeHelper.route('account')
}
export function routeLogin(): string {
	return routeHelper.route('login', undefined, '?rurl=1')
}
export function routeWishlist(): string {
	return routeHelper.route('wishlist')
}
export function routeResults(key: string, id: string): string {
	return routeHelper.route('results', key, id)
}
export function routeShop(id: string): string {
	return routeHelper.route('shop', id + '.html')
}
export function routeBag(): string {
	return routeHelper.route('bag')
}
export function routeContentPage(id: string): string {
	return routeHelper.route('page', id)
}

/* Request folder vs cgid Helper Functions */
export function getSFCCSecondary(): string[] {
	return routeHelper.sfccSecondary()
}
export function getSFCCPrefixes(): PLPDirsType {
	return routeHelper.sfccPrefixes()
}
export function getSFCCPrefix(layout: string, id: string): string {
	return routeHelper.sfccPrefix(layout, id)
}
export function getRemovedSFCCPrefix(
	dir: string,
	layout: string,
	canTranslate?: boolean
): string {
	return routeHelper.removeSFCCPrefix(plpPrefix, dir, layout, canTranslate)
}
export function getRemovedSFCCPrefixSKU(sku: string, layout: string): string {
	return routeHelper.removeSFCCPrefixSKU(sku, layout)
}
export function getSFCCFamilyID(
	brandId: string | undefined,
	familyId: string | undefined
): string {
	if (brandId && familyId && brandId in familyOverride) {
		if (familyId in familyOverride[brandId]) {
			return brandId + '-' + familyOverride[brandId][familyId]
		} else {
			return brandId + '-' + scrubDoubleBrand(brandId, familyId)
		}
	}
	return brandId && familyId && familyId.indexOf(brandId + '-') == -1
		? brandId + '-' + familyId
		: ''
}
export function getRemovedFamilyId(cgid: string, brandId: string): string {
	let ret: string = cgid

	if (cgid && brandId) {
		if (brandId in familyOverride && cgid in familyOverride[brandId]) {
			ret = familyOverride[brandId][cgid]
		} else if (brandId in familyOverride) {
			ret = scrubDoubleBrand(brandId, cgid)
		} else if (cgid.indexOf(brandId + '-') == 0) {
			ret = cgid.replace(new RegExp(`^${brandId}-`), '')
		}
	}
	return ret
}
export function getCollection(
	layout: string,
	cgid: string,
	cgidParent: string,
	specialTopLevel?: boolean
): getCollectionReturn {
	let enforceAttribute: string = ''
	let isCollection: boolean = false
	let isCollectionName: string = ''
	let collectionType: string = cgid.replace(layout + '-', '')
	let cgidType: string = ''

	/* Is Collection? */
	const isCollectionKey: string = cgid
	if (layout !== 'jewelry') {
		collectionPrefixes.forEach((prefix) => {
			isCollection = isCollection || cgid.indexOf(prefix) > -1 ? true : false
			collectionType = collectionType.replace(prefix, '')
		})
	}

	/* Page Types */
	const cgidTypes: { [key: string]: string } = {
		all: 'all',
		brand: 'brand',
		family: 'family',
		collection: 'collection',
	}

	/* Determine Page Type */
	if (isCollection && collectionType in collectionTypes) {
		enforceAttribute = collectionTypes[collectionType]
		isCollectionName = collectionNames[collectionType]
		cgidParent = cgid
		cgid = layout
		cgidType = cgidTypes.collection
	} else if (specialTopLevel) {
		if (cgid && cgid !== layout) {
			cgidType = cgidTypes.family
		} else {
			cgidType = cgidTypes.brand
		}
	} else {
		if (cgidParent && cgidParent !== layout) {
			cgidType = cgidTypes.family
		} else if (cgid !== layout) {
			cgidType = cgidTypes.brand
		} else {
			cgidType = cgidTypes.all
		}
	}

	return {
		cgid: cgid,
		cgidParent: cgidParent,
		cgidType: cgidType,
		enforceAttribute: enforceAttribute,
		isCollection: isCollection ? isCollectionKey : '',
		isCollectionName: isCollection ? isCollectionName : '',
	}
}
export const getSFCCSKUPrefix = (layout: string, id: string): string => {
	/* Legacy Jewelry Prefixes */
	if (id.indexOf('jp-') === 0 || id.indexOf('jn-') === 0) {
		return 'ns-' + id
	}

	return layout in prefixAdditions ? prefixAdditions[layout] + id : id
}
