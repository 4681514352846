const cookieHelperClient = {
	getCookie: (key: string): string => {
		let ret = ''
		const ck: string = encodeURIComponent(key) + '='
		const ca: string[] = document.cookie.split(';')
		let c = ''
		for (let i = 0; i < ca.length; i++) {
			c = ca[i]
			while (c.charAt(0) === ' ') {
				c = c.substring(1, c.length)
			}
			if (c.indexOf(ck) === 0) {
				ret = decodeURIComponent(c.substring(ck.length, c.length)) || ''
				break
			}
		}
		return ret
	},
	setCookie: (
		key: string,
		val: string,
		days?: number,
		withDomain?: boolean
	): void => {
		let expires: string = '; expires='

		const domain: string = withDomain ? '; domain=' + location.hostname : ''
		if (days === 0) {
			expires += '0'
		} else if (days) {
			const date: Date = new Date()
			date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
			expires += date.toUTCString()
		} else {
			expires = ''
		}

		document.cookie =
			encodeURIComponent(key) +
			'=' +
			encodeURIComponent(val) +
			expires +
			'; path=/' +
			domain

		/* Dispatch event for cookie change listeners */
		window.dispatchEvent(
			new CustomEvent('cookie:set', {
				bubbles: true,
				cancelable: true,
				detail: { key, val, days, withDomain },
			})
		)
	},
}

/* Named functions */
export function getCookie(key: string): string {
	return cookieHelperClient.getCookie(key)
}
export function setCookie(
	key: string,
	val: string,
	days?: number,
	withDomain?: boolean
): void {
	return cookieHelperClient.setCookie(key, val, days, withDomain)
}
