import { useEffect } from 'react'

/* Global Config */
import { domainNoPath } from '@lib/config/global'

export const useEscapeFrame = (): void => {
	useEffect(() => {
		/* Client side? */
		if (typeof window !== 'undefined') {
			/* If URL contains pig-env, escape to production */
			if (window.location.href.includes('pig-env-')) {
				window.location.href = `${domainNoPath}${window.location.pathname}${window.location.search}`
				return
			}

			/* If inside frame, should we escape frame to top? */
			if (window.top && window.top !== window.self) {
				if (window.location.hostname === window.top.location.hostname) {
					/* Allow the website to iframe itself (Ex: Live Shopping Widget) */
					return
				} else if (window.location.search.indexOf('isMobileApp') === -1) {
					/* Allow the mobile app to iframe the website */
					window.top.location = window.self.location
					return
				}
			}
		}
	}, [])
}
