import { useEffect, useRef, useState } from 'react'
import { usePathname } from '@core/navigation'

/* Global State */
import { setGlobalState, getGlobalState } from '@state/global'

/* Hooks */
import {
	useScrollTopTo,
	useScrollToAnchor,
	useScrollReset,
} from '@hooks/reducedMotionHook'
import { useDebounce } from '@hooks/useDebounce'

/* Local Types */
type scrollsType = {
	[key: string]: number | undefined
}

/* Local Constants */
const stateKey: string = 'scroll'
const restoreBehavior: ScrollBehavior = 'smooth'
const resetBehavior: ScrollBehavior = 'instant'
const scrollMin: number = 10

/* Scrub Keys */
const scrubKeys = (obj: scrollsType): scrollsType => {
	Object.keys(obj).forEach((key) => {
		if (typeof obj[key] !== 'number' || obj[key] < scrollMin) {
			delete obj[key]
		}
	})
	return obj
}

/* Return number if present, else undefined */
const getScrollToValue = (pathname: string): number | undefined => {
	const scrollTo = getCurrentScrolls()?.[pathname] as number | undefined
	return typeof scrollTo === 'number' ? scrollTo : undefined
}

/* Retain only the last 10 scroll positions */
const removeOldKeys = (
	obj: scrollsType,
	numberToKeep: number
): scrollsType | null => {
	/* Remove keys that are not numbers or less than scrollMin */
	obj = scrubKeys(obj)

	const keys = Object.keys(obj)
	if (keys.length > numberToKeep) {
		return keys.slice(-numberToKeep).reduce((acc, key) => {
			acc[key] = obj[key]
			return acc
		}, {} as scrollsType)
	} else {
		return obj
	}
}

/* Get and Set Current Scroll for id */
const getCurrentScrolls = (updateId?: string): scrollsType => {
	/* Get Global State */
	const ret = (getGlobalState()?.[stateKey] || {}) as scrollsType

	/* Remove updateId if present */
	if (updateId && typeof ret[updateId] === 'number') {
		delete ret[updateId]
	}
	return ret
}
const setCurrentScrolls = (scrollId: string, scrollY?: number): void => {
	/* Skip update if scrolled to top */
	if (typeof scrollY !== 'undefined' && scrollY < scrollMin) {
		return
	}

	/* Cleanup before adding new key */
	const cleanScrolls = removeOldKeys(
		getCurrentScrolls(scrollId),
		10
	) as scrollsType
	if (typeof scrollY === 'undefined') {
		if (scrollId in cleanScrolls) {
			delete cleanScrolls[scrollId]
		} else {
			cleanScrolls[scrollId] = undefined
		}
	} else {
		cleanScrolls[scrollId] = scrollY
	}

	/* Save to Global State */
	setGlobalState({ [stateKey]: scrubKeys(cleanScrolls) })
}

/* Clear saved scrollY for pathname -- Ex: when going forward to a page in the back-cache */
export function setClearScroll(pathname: string): void {
	setGlobalState({
		[stateKey]: scrubKeys({
			...((getGlobalState()?.[stateKey] || {}) as scrollsType),
			[pathname]: undefined,
		}),
	})
}

/* Restore scroll after lazy load render is complete */
export function useLazyLoadRestoreScroll(): void {
	const pathname = usePathname()
	useEffect(() => {
		const scrollTo = getScrollToValue(pathname)
		if (scrollTo) {
			useScrollTopTo(scrollTo, restoreBehavior)
			setCurrentScrolls(pathname, undefined)
		}
	}, [pathname])
}

/* Restore Scroll manager for any given pathname -- saves up to 10 most recent */
export function useRestoreScroll(hash?: string): void {
	/* Avoid auto-scroll animation from updating state */
	const [canUpdate, setCanUpdate] = useState<boolean>(true)

	/* Hash Ref */
	const hashRef = useRef<string | undefined>(undefined)

	/* Pathname */
	const pathname = usePathname()

	/* Debounce to reduce State updates */
	const debounceUpdateState = useDebounce(300)
	const debounceLockState = useDebounce(600)

	/* Record Scroll Position only when body is scrollable */
	useEffect(() => {
		const onScroll = () => {
			if (canUpdate) {
				/* If body is fixed, scrollY will be 0 */
				const bodyFixed: boolean =
					window
						.getComputedStyle(document.body)
						?.getPropertyValue('position') === 'fixed'
						? true
						: false
				const bodyFixedClass: boolean = document.body?.classList.contains(
					'body-fixed'
				)
					? true
					: false
				const bodyWaitClass: boolean = document.body?.classList.contains('wait')
					? true
					: false

				/* If body is not fixed, record scroll position */
				const scrollY = window.scrollY
				const scrollId = pathname
				if (!bodyFixed && !bodyFixedClass && !bodyWaitClass) {
					debounceUpdateState(() => {
						if (canUpdate) {
							setCurrentScrolls(scrollId, scrollY)
						}
					})
				}
			}
		}

		/* Block updating for a short time */
		setCanUpdate(false)
		debounceLockState(() => {
			setCanUpdate(true)
		})

		/* Wait for initial render and determine action */
		setTimeout(() => {
			/* Scroll to hash present? */
			const resetScroll: boolean =
				hash && hashRef.current !== hash && useScrollToAnchor(hash)
					? false
					: true

			/* Update hash ref */
			hashRef.current = hash

			/* If no hash scroll, reset/restore scroll */
			if (resetScroll) {
				const scrollTo = getScrollToValue(pathname)
				if (scrollTo) {
					if (window.innerHeight < scrollTo) {
						/* If window is smaller than scrollTo, Reset Scroll */
						useScrollReset(resetBehavior)
					} else {
						/* Scroll to saved position then remove pathname from Global State */
						useScrollTopTo(scrollTo, restoreBehavior)
						setCurrentScrolls(pathname, undefined)
					}
				} else {
					/* Reset Scroll */
					useScrollReset(resetBehavior)
				}
			}
		}, 50)

		/* Event Listener */
		window.addEventListener('scroll', onScroll)
		return () => {
			window.removeEventListener('scroll', onScroll)
		}
	}, [pathname, hash])
}
