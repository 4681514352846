import { useEffect } from 'react'

/* Global State */
import { setGlobalState, getGlobalState } from '@state/global'

/* Shared Types */
import {
	EtmcRecordType,
	EtmcDataType,
	DataType,
	DependenciesType,
	RawDataType,
	RawBasketType,
	CartType,
} from './useMarketingCloudTracking.models'

/* Global Types */
declare const window: {
	_etmc: Array<EtmcRecordType>
} & Window

/* Constants */
const orgId: string = '514005192' // Fixed Marketing Cloud Org ID
const orgIdKey: string = 'setOrgId'
const scriptId: string = 'etmc'
const scripURL: string = `https://${orgId}.collect.igodigital.com/collect.js`
const emailKeys: string[] = ['smfc_id', 'sk']
const dataKeys: string[] = ['category', 'item', 'search', 'cart']
const useInfoEvent: string = 'setUserInfo'
const pageViewEvent: string = 'trackPageView'
const globalStateKey: string = 'marketingCloudStarted'
const globalDebugKey: string = 'debugMarketingCloud'

/* Shared Helpers */
export const sendMarketingCloudData = (data: EtmcRecordType): void => {
	if (typeof window._etmc === 'undefined' || !window._etmc) {
		window._etmc = []
	}
	window._etmc.push(data)

	/* Debug? */
	if (getGlobalState()?.[globalDebugKey]) {
		console.info('Marketing Cloud Data:', data)
	}
}
export const sendMarketingCloudEmailValue = (
	email: string,
	withPageView?: boolean
): void => {
	sendMarketingCloudData([
		useInfoEvent,
		{
			user_info: {
				email: email,
			},
		},
	])
	if (withPageView) {
		sendMarketingCloudPageView({})
	}
}
export const sendMarketingCloudPageView = (data: EtmcDataType): void => {
	sendMarketingCloudData([pageViewEvent, data])
}
export const startMarketingCloud = (): void => {
	/* Enable debugging? */
	if (window.location?.search?.includes(globalDebugKey)) {
		setGlobalState({ [globalDebugKey]: true })
	}

	/* Set Org ID */
	sendMarketingCloudData([orgIdKey, orgId])
}

/* Local Helpers */
const getHasStarted = (): boolean => {
	return (getGlobalState()?.[globalStateKey] as boolean) || false
}
const getMarketingCloudScript = (): HTMLScriptElement | null => {
	return document.getElementById(scriptId) as HTMLScriptElement
}

/* Hook for Page View or Email Submission */
export const useMarketingCloudTracking = (
	data?: DataType | null,
	dependencies?: DependenciesType
): void => {
	useEffect(() => {
		/* Single script */
		const scriptElem = getMarketingCloudScript()

		/* Initialize? */
		if (!getHasStarted() || !scriptElem) {
			startMarketingCloud()
			setGlobalState({ [globalStateKey]: true })
		}

		/* Inject script once */
		if (!scriptElem) {
			const script = document.createElement('script')
			script.id = scriptId
			script.src = scripURL
			script.async = true
			document.head.appendChild(script)
		}
	}, [])

	useEffect(() => {
		/* Skip if intentionally null data */
		if (data !== null) {
			/* Track email from explicit value else incoming query params */
			let email: string = data ? data.email || '' : ''
			if (!email) {
				const urlParams = new URLSearchParams(window.location.search)
				emailKeys.forEach((key) => {
					if (!email && urlParams.has(key)) {
						email = urlParams.get(key) || ''
					}
				})
			}
			if (email) {
				sendMarketingCloudEmailValue(email, false)
			}

			/* Track page data */
			const etmcData: EtmcDataType = {}
			if (data && Object.keys(data).length > 0) {
				dataKeys.forEach((key) => {
					if (data[key]) {
						etmcData[key] = data[key]
					}
				})
			}

			/* Send page view */
			sendMarketingCloudPageView(etmcData)
		}
	}, dependencies || [])
}

/* Hook for Add to Cart */
export const sendMarketingCloudAddToBasket = (data: RawDataType): void => {
	const getBasketItems = (basket: RawBasketType): CartType | undefined => {
		/* Check if basket is valid */
		if (!basket) {
			return undefined
		}

		/* Check if basket items are valid */
		const { items } = basket
		if (!items || !Array.isArray(items) || items.length === 0) {
			return undefined
		}

		/* Convert basket{items:{}} to cart[] */
		return items.map((item) => {
			return {
				item: item.masterId || item.id,
				quantity: item.quantity,
				price: item.price?.sales?.value || item.price?.list?.value || 0,
				unique_id: item.id,
			}
		})
	}

	if (data && data.basket) {
		const cart = getBasketItems(data.basket)
		if (cart && cart.length > 0) {
			/* Initialize? */
			if (!getHasStarted()) {
				startMarketingCloud()
			}

			/* Send Add to Cart */
			sendMarketingCloudData([pageViewEvent, { cart: cart }])
		}
	}
}
