const config = {
	supportedLanguages: [
		{
			language: 'en',
			name: 'English',
		},
	],
}

export const supportedLanguages = config.supportedLanguages as {
	language: string
	name: string
}[]

export default config
