const config = {
	accordionExclusions: {
		SG: ['patek-philippe'],
	},
	navExclusions: {
		SG: ['patek-philippe'],
	},
}

export const accordionExclusions = config.accordionExclusions as {
	[key: string]: string[]
}
export const navExclusions = config.navExclusions as { [key: string]: string[] }

export default config
